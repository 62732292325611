import isArray from 'lodash/isArray';
import flow from 'lodash/flow';
import uniq from 'lodash/uniq';

import { parseMilliseconds } from '@uptime/shared/utils/general';
import { SUPPORT_TYPE } from '../constants/marketplace';

const mapFacilityTitles = (facilities, devices) => (item) => {
  try {
    const { options } = devices.find(({ facilityId }) => facilityId === item.facilityId);

    return { ...item, options };
  } catch {
    return item;
  }
};

const mapEquipmentCategories = (devices) => (item) => {
  try {
    const { categories } = isArray(devices)
      ? devices.find(({ facilityId }) => facilityId === item.facilityId)
      : {};

    return { ...item, categories };
  } catch {
    return item;
  }
};

export const alphabeticalSorting = (key) => (a, b) => a[key].localeCompare(b[key]);

export const getMappedDevices = (devices, facilities, initialValues) =>
  isArray(facilities)
    ? facilities
        .map((facility) => {
          const { devices: initialDevices } = initialValues || {};
          const deviceMapper = flow(
            mapFacilityTitles(facilities, devices),
            mapEquipmentCategories(initialDevices)
          );

          return deviceMapper(facility);
        })
        .sort(alphabeticalSorting('title'))
    : [];

export const getFacilityAttributes = (fields, name, index) => {
  const { value } = fields || { value: [] };
  const { options, title, categories, facilityId } = value[index] || {};

  return {
    facilityId,
    categoriesName: `${name}.categories`,
    categoriesOptionsValue: options,
    facilityTitleValue: title,
    categoriesValue: categories,
  };
};

export const getContracts = (contracts, { contracts: initialContracts } = {}) => {
  if (!contracts) return [];

  return contracts
    .filter(({ options }) => Boolean(options))
    .map((item) => {
      const { options, categories, facilityId, title } = item;

      const initContract =
        isArray(initialContracts) && initialContracts.find((item) => item.facilityId === facilityId);

      const { options: initialOptions, contracts } = initContract || {};

      const filteredOptions = options.filter(
        (option) => isArray(categories) && categories.indexOf(option.value) > -1
      );

      const mappedOptions = filteredOptions.map(({ label, value }) => {
        const initOption = isArray(initialOptions) && initialOptions.find((item) => item.id === value);
        const { rate } = initOption || {};

        return {
          id: value,
          label,
          rate,
        };
      });

      return {
        facilityId,
        title,
        contracts,
        options: mappedOptions,
      };
    })
    .sort(alphabeticalSorting('title'));
};

export const createServiceProviderInput = (input) => {
  const { contractsFormValues, facilitiesFormValues, previousEquipments, previousFacilities } = input;

  const equipmentsData = {
    createData: {
      newFacilityIds: [],
      data: [],
    },
    updateData: [],
    deleteData: {
      deleteCategories: [],
      deleteFacilities: [],
    },
  };

  // New Facility createData
  for (const facility of facilitiesFormValues.facilities) {
    if (!previousFacilities.some((prev) => prev.facilityId === facility.facilityId)) {
      equipmentsData.createData.newFacilityIds.push(facility.facilityId);
      equipmentsData.createData.data.push({
        facilityId: facility.facilityId,
        facilityTitle: facility.title,
        rate: 0,
      });
    }
  }

  // Deleted Facility deleteData
  for (const facility of previousFacilities) {
    if (!facilitiesFormValues.facilities.some((curr) => curr.facilityId === facility.facilityId)) {
      equipmentsData.deleteData.deleteFacilities.push({
        facilityId: facility.facilityId,
      });
    }
  }

  if (!contractsFormValues) {
    // Tech is facility only, remove all categories
    for (const previousEquipment of previousEquipments) {
      const deleteCategory = {
        facilityId: previousEquipment.facilityId,
        equipmentCategoryId: previousEquipment.equipmentCategoryId,
      };
      equipmentsData.deleteData.deleteCategories.push(deleteCategory);
    }
  } else {
    const contractsOptionsMap = new Map();
    contractsFormValues.contracts.forEach((contract) => {
      contractsOptionsMap.set(contract.facilityId, contract.options);
    });

    // Handle adding new categories for each facility
    for (const contract of contractsFormValues.contracts) {
      for (const option of contract.options) {
        const previousEquipment = previousEquipments.find(
          (equip) => equip.facilityId === contract.facilityId && equip.equipmentCategoryId === option.id
        );

        if (!previousEquipment) {
          const equipmentData = {
            facilityId: contract.facilityId,
            facilityTitle: contract.title,
            rate: option.rate,
            equipmentCategoryId: option.id,
            equipmentCategoryTitle: option.label,
          };
          equipmentsData.createData.data.push(equipmentData);
        }
      }
    }

    // Handle deleting categories not in contractsFormValues
    for (const previousEquipment of previousEquipments) {
      const contractsOptions = contractsOptionsMap.get(previousEquipment.facilityId);

      if (
        !contractsOptions ||
        !contractsOptions.some((option) => option.id === previousEquipment.equipmentCategoryId)
      ) {
        const deleteCategory = {
          facilityId: previousEquipment.facilityId,
          equipmentCategoryId: previousEquipment.equipmentCategoryId,
        };
        equipmentsData.deleteData.deleteCategories.push(deleteCategory);
      }
    }

    // Handle updating rates
    for (const contract of contractsFormValues.contracts) {
      for (const option of contract.options) {
        const previousEquipment = previousEquipments.find(
          (equip) =>
            equip.facilityId === contract.facilityId &&
            equip.equipmentCategoryId === option.id &&
            equip.rate !== option.rate
        );

        if (previousEquipment) {
          const updateData = {
            facilityId: contract.facilityId,
            equipmentCategoryId: option.id,
            rate: option.rate,
          };
          equipmentsData.updateData.push(updateData);
        }
      }
    }
  }

  return equipmentsData;
};

export const getMutatedParamsForServiceProviders = (params) => {
  const { sort: { column, order: lowerCaseOrder } = {}, ...otherParams } = params || {};

  const order = lowerCaseOrder && lowerCaseOrder.toUpperCase();

  const sortBy =
    column === 'technicianFullName'
      ? [
          {
            field: 'technicianFirstName',
            order,
          },
          {
            field: 'technicianLastName',
            order,
          },
        ]
      : [
          {
            field: column,
            order,
          },
        ];

  const sortParams = column ? { sortBy } : {};

  return {
    ...otherParams,
    ...sortParams,
  };
};

export const getExpandedList = (errorList = [], defaultList = [], listName) => {
  const shouldBeExpanded = errorList.reduce((accumulator, current, index) => {
    const newItem = current ? [`${listName}[${index}]`] : [];
    return [...accumulator, ...newItem];
  }, defaultList);

  return uniq(shouldBeExpanded);
};

export const getFacilityEquipmentRate = (equipments = []) =>
  equipments.reduce((accumulator, current) => {
    const { facilityId, facilityTitle, equipmentCategoryTitle, equipmentCategoryId, rate } = current;
    const existingFacilityObject = accumulator[facilityTitle] || { facilityId, facilityTitle };
    const existingCategories =
      accumulator[facilityTitle] && accumulator[facilityTitle].categories
        ? accumulator[facilityTitle].categories
        : [];
    const newCategoryObject = {
      id: equipmentCategoryId,
      title: equipmentCategoryTitle,
      rate,
    };

    return {
      ...accumulator,
      [facilityTitle]: {
        ...existingFacilityObject,
        categories: [...existingCategories, newCategoryObject],
      },
    };
  }, {});

const prepareContracts = (fetchedContracts, facilityId) => {
  if (!isArray(fetchedContracts)) return [];

  return fetchedContracts
    .filter((item) => item.facilityId === facilityId)
    .map((item) => {
      const { start, end, ...other } = item;
      const startDate = start
        ? {
            start: new Date(parseMilliseconds(start)),
          }
        : {};
      const endDate = end
        ? {
            end: new Date(parseMilliseconds(end)),
          }
        : {};

      return {
        ...other,
        ...startDate,
        ...endDate,
      };
    });
};

export const getEditValues = (spFacility, spCategories, fetchedContracts, supportType) => {
  // Get facilities from spFacility for facility only techs
  const facilities = spFacility.map(({ facilityId, facilityTitle }) => ({
    facilityId,
    title: facilityTitle,
  }));

  const merged = spCategories.map(({ facilityId, ...rest }) => ({
    facilityId,
    ...rest,
    facilityTitle: spFacility.find(({ facilityId: id }) => id === facilityId)?.facilityTitle,
  }));

  const facilityRates = Object.values(getFacilityEquipmentRate(merged));

  const toReturn = facilityRates.reduce(
    ({ facilities, devices, contracts }, currentValue) => {
      const { categories, facilityId, facilityTitle } = currentValue;

      const newFacility = {
        facilityId,
        title: facilityTitle,
      };

      const newDevice = {
        ...newFacility,
        categories: categories.map(({ id }) => id),
        options: categories.map(({ id, title }) => ({ label: title, value: id })),
      };

      const filteredContracts = prepareContracts(fetchedContracts, facilityId);

      const options = categories.map(({ equipmentId, id, title, rate }) => ({
        equipmentId,
        id,
        label: title,
        rate,
      }));

      const newContract = {
        facilityId,
        title: facilityTitle,
        contracts: filteredContracts,
        options,
      };

      return {
        facilities: [...facilities, newFacility],
        devices: [...devices, newDevice].sort(alphabeticalSorting('title')),
        contracts: [...contracts, newContract].sort(alphabeticalSorting('title')),
      };
    },
    {
      facilities: [],
      devices: [],
      contracts: [],
    }
  );

  return {
    ...toReturn,
    facilities,
    supportTypeValues: {
      isFacility: [SUPPORT_TYPE.FACILITY, SUPPORT_TYPE.ALL].includes(supportType),
      isDevice: [SUPPORT_TYPE.DEVICE, SUPPORT_TYPE.ALL].includes(supportType),
    },
  };
};

export const getFacilityTitleInEquipments = (equipments, facilityId) => {
  try {
    const { facilityTitle } = equipments.find((item) => item.facilityId === facilityId);

    return facilityTitle;
  } catch {
    return '';
  }
};

export const ensureAllFacilitiesInContracts = (initialValues, selectedFacilities) => {
  const updatedInitialValues = { ...initialValues, contracts: [...initialValues.contracts] };

  // Iterate through selectedFacilities and check if each facility exists in contracts
  selectedFacilities.forEach((selectedFacility) => {
    if (
      !updatedInitialValues.contracts.some((contract) => contract.facilityId === selectedFacility.facilityId)
    ) {
      // If it does not exist, add the facility with empty contracts and options arrays
      updatedInitialValues.contracts.push({
        ...selectedFacility,
        contracts: [],
        options: [],
      });
    }
  });

  updatedInitialValues.contracts.sort(alphabeticalSorting('title'));
  return updatedInitialValues;
};
