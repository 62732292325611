import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  loadingBox: {
    background: `${theme.palette.secondary.light}`,
    animation: '$pulse 1.5s ease-in-out 0.5s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.4,
    },
    '100%': {
      opacity: 1,
    },
  },
}));
