import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { UIContext } from '@uptime/controller/src/providers/UIProvider';
import useStyles from '../styles';

const Head = (props) => {
  const { order, orderBy, onRequestSort, headCells, selectable, rowCount, onSelectAllClick, tableTitle } =
    props;

  const classes = useStyles();

  const { selectableTable = {} } = useContext(UIContext);

  const { option, include, exclude } = selectableTable;

  const isChecked =
    (option === 'all' && exclude.length === 0) || (option === 'unselected' && include.length === rowCount);
  const isIndeterminate =
    (option === 'all' && exclude.length > 0) ||
    (option === 'unselected' && include.length > 0 && include.length < rowCount);

  return (
    <TableHead data-testid="tableHead">
      {tableTitle && (
        <TableRow className={classes.tableHeading}>
          <TableCell colSpan={3}>
            <Typography variant="h4" className={classes.tableTitle}>
              {tableTitle}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        {selectable && (
          <TableCell>
            <Checkbox
              color="primary"
              onChange={onSelectAllClick}
              checked={isChecked}
              indeterminate={isIndeterminate}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ ...headCell.style, whiteSpace: 'nowrap' }}
          >
            {headCell.isSortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={onRequestSort(headCell.id)}
                IconComponent={ExpandMoreIcon}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

Head.propTypes = {
  rowCount: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectable: PropTypes.bool,
  tableTitle: PropTypes.string,
};

export default Head;
