import { useWidth } from './useWidth';

export const useIsCellPhoneView = () => {
  const width = useWidth();

  return ['xs'].indexOf(width) > -1;
};

export const useIsTabletView = () => {
  const width = useWidth();

  return ['xs', 'sm'].indexOf(width) > -1;
};

export default () => {
  const width = useWidth();

  return ['xs', 'sm', 'md'].indexOf(width) > -1;
};
