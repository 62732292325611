import React, { Fragment } from 'react';
import uuid from 'uuid';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiList from '@mui/material/List';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { ORDER_DESC } from '../index';
import useStyles from '../styles';
import ListButton from './ListButton';

const ACTION_ID = 'actions';

const MobileList = (props) => {
  const { order, orderBy, onRequestSort, headCells, bodyCells } = props;
  const classes = useStyles();

  return (
    <div>
      <Box m={1} boxShadow={1}>
        <Divider />
      </Box>
      {bodyCells.map((row) => (
        <Fragment key={uuid.v4()}>
          <Box display="flex">
            <Box>
              <MuiList component={Grid} className={classes.list} container>
                {headCells.map(({ id, label, isSortable }) => {
                  const listItemClasses = classNames({
                    [classes.activeSorting]: orderBy === id,
                    [classes.listItem]: true,
                  });

                  const sortable = isSortable ? { onClick: onRequestSort(id) } : {};

                  return (
                    id !== ACTION_ID && (
                      <ListItem
                        component={Grid}
                        key={id}
                        xs={12}
                        sm={6}
                        md={4}
                        button={isSortable}
                        className={listItemClasses}
                        item
                      >
                        <ListItemText
                          className={classes.listItemText}
                          primary={<ListButton {...sortable}>{row[id]}</ListButton>}
                          secondary={label}
                        />
                        {orderBy === id && (
                          <ListItemIcon>
                            {order === ORDER_DESC ? <ArrowDownIcon /> : <ArrowUpIcon />}
                          </ListItemIcon>
                        )}
                      </ListItem>
                    )
                  );
                })}
              </MuiList>
            </Box>
            <Box pt={2}>{row[ACTION_ID]}</Box>
          </Box>
          <Box m={1} boxShadow={1}>
            <Divider />
          </Box>
        </Fragment>
      ))}
    </div>
  );
};

MobileList.propTypes = {
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  bodyCells: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MobileList;
