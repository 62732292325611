import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const ListButton = ({ onClick, children }) => (
  <Box>
    <Box position="relative" zIndex={1}>
      {children}
    </Box>
    <Box position="absolute" width="100%" height="100%" top={0} left={0} onClick={onClick} />
  </Box>
);

ListButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default ListButton;
