import isNumber from 'lodash/isNumber';
import subHours from 'date-fns/subHours';
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import { formatInTimeZone } from 'date-fns-tz';
import parse from 'date-fns/parse';
import isDate from 'date-fns/isDate';
import getTime from 'date-fns/getTime';
import subSeconds from 'date-fns/subSeconds';
import endOfToday from 'date-fns/endOfToday';
import subDays from 'date-fns/subDays';
import getUnixTime from 'date-fns/getUnixTime';
import addMinutes from 'date-fns/addMinutes';
import moment from 'moment';

import { ARRIVE_WINDOW } from '../constants/wo';

const DEFAULT_DAYS_DELTA = 7;
const MINUTES_IN_HOUR = 60;

export const dateFormat = (time) => time && format(time, 'MMM d, yyyy');

export const dateFormatMySQLDate = (time) => time && formatInTimeZone(time, 'UTC', 'MMM d, yyyy');

export const timeFormat = (time) => time && format(time, 'hh:mm a');

export const fullDateFormat = (time) => time && format(time, 'hh:mm a, MMM d, yyyy');

export const getFormattedDate = (date) => (isDate(date) ? dateFormat(date.getTime()) : null);

const convertDateToTimestamp = (date) => Number((date.getTime() / 1000).toFixed(0));

export const getUnixTimestamp = (date) => {
  if (isDate(date)) return Number(convertDateToTimestamp(date));
  if (isNumber(date)) return convertDateToTimestamp(new Date(date));
  return null;
};

export const getDaylightCorrection = (date, currentDate = new Date(), isView = true) => {
  const isDateInput = !(isDate(date) && isDate(currentDate));

  if (isDateInput) return undefined;

  const isDateDst = moment(date).isDST();

  if (currentDate) {
    const isCurrentDateDst = moment(currentDate).isDST();
    if (isCurrentDateDst) {
      if (isDateDst) {
        return date;
      } else {
        return addHours(date, 1);
      }
    }
  }

  return isDateDst ? subHours(date, 1) : date;
};

export const fromString = (dateString) => dateString && new Date(dateString.replace('-', '/'));

export const getISODateFromYear = (year) => {
  if (!year) return undefined;

  return new Date(`January 01, ${year} 23:00:00`).toISOString();
};

export const getDefaultTimeRange = () => {
  const endTime = getTime(subSeconds(endOfToday(), 1));
  const sevenDaysFromToday = getTime(subDays(endTime, DEFAULT_DAYS_DELTA));

  return {
    start: getUnixTime(sevenDaysFromToday),
    end: getUnixTime(endTime),
  };
};

export const toHoursMinutes = (date) => format(date, 'hh:mm aa');

export const getArriveWindow = (date = new Date(), arriveWindowInterval = ARRIVE_WINDOW.EXACT) => {
  const startDate = toHoursMinutes(date);

  if (arriveWindowInterval === ARRIVE_WINDOW.EXACT) return startDate;

  return [startDate, toHoursMinutes(addMinutes(date, arriveWindowInterval))].join(' - ');
};

/**
 * Gets days delta from now
 * @param date {Date}
 * @param days {number}
 * @returns {number}
 */
export const getDateDelta = ({ date, days }) => {
  return new Date(new Date(date).setDate(date.getDate() - days));
};

export const getLocalUnixTimestamp = (unixTimestamp) => {
  const offset = new Date().getTimezoneOffset();
  const sign = offset > 0 ? -1 : 1;

  return unixTimestamp + Math.abs(offset) * sign * 60;
};

/**
 * Returns the current Unix time in seconds.
 * @returns {number} The current Unix time in seconds.
 */
export const getCurrentUnixTimeInSeconds = () => Math.floor(Date.now() / 1000);

export const getFormattedTimeStamp = (timeStamp) => {
  let open_time;
  if (timeStamp.includes(':')) {
    open_time = parse(timeStamp, 'HH:mm', new Date());
    if (format(open_time, 'mm') === '00') {
      return format(open_time, 'h a');
    } else {
      return format(open_time, 'hh:mm a');
    }
  } else {
    open_time = parse(timeStamp, 'ha', new Date());
    return format(open_time, 'hh:mm a');
  }
};

export const isPastEstDate = () => {
  const now = new Date();
  const date = now.getDate();
  const dateInEst = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' })).getDate();
  return dateInEst > date;
};

export const addDaysToCurrentDate = (days) => {
  return new Date(new Date().valueOf() + 24 * 60 * 60 * 1000 * days);
};
