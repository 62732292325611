export const ACTION_LINKS = {
  create: '/app/service-providers/create',
  find: '/app/marketplace',
};

export const WIZARD_TITLES = {
  TECH_INFORMATION: 'Tech Info',
  FACILITIES: 'Facilities',
  DEVICES: 'Devices',
  CONTRACTS: 'Contracts',
  APPROVAL: 'Approval',
};

export const WIZARD_PAGES = {
  TECH_INFORMATION: 'TECH_INFORMATION',
  FACILITIES: 'FACILITIES',
  SUPPORT: 'SUPPORT',
  CONTRACTS: 'CONTRACTS',
  APPROVAL: 'APPROVAL',
};

export const ACTION_TYPES = {
  SET_STEP: 'SET_STEP',
  REQUEST_START: 'REQUEST_START',
  REQUEST_ERROR: 'REQUEST_ERROR',
  REQUEST_END: 'REQUEST_END',
  CLEAR_ERROR: 'CLEAR_ERROR',
  FINALIZE_TECH_INFO_STEP: 'FINALIZE_TECH_INFO_STEP',
  FINALIZE_FACILITIES_STEP: 'FINALIZE_FACILITIES_STEP',
  FINALIZE_SUPPORT_STEP: 'FINALIZE_SUPPORT_STEP',
  FINALIZE_CONTRACTS_STEP: 'FINALIZE_CONTRACTS_STEP',
  FINALIZE_APPROVAL_STEP: 'FINALIZE_APPROVAL_STEP',
  RESET_ALL: 'RESET_ALL',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
  SET_PREVIOUS_EQUIPMENTS: 'SET_PREVIOUS_EQUIPMENTS',
  SET_PREVIOUS_FACILITIES: 'SET_PREVIOUS_FACILITIES',
  SET_SUPPORT_TYPE: 'SET_SUPPORT_TYPE',
};

export const FACILITY_SELECT_HEADERS = (selectAllAction) => [
  { id: 'id', isSortable: false, label: selectAllAction, isVisible: true, style: { minWidth: 'auto' } },
  { id: 'title', isSortable: true, label: 'Name', isVisible: true },
  {
    id: 'addressOne',
    isSortable: false,
    label: 'Address',
    isVisible: true,
    style: {
      minWidth: 'auto',
      maxWidth: 'none',
      overflowWrap: 'break-word',
      wordBreak: 'inherit',
    },
  },
  { id: 'location', isSortable: false, label: 'Location', isVisible: true, style: { minWidth: 'auto' } },
];

export const ALL_DEVICE_CATEGORIES = {
  YES: '1',
  NO: '0',
};

export const CONTRACTS_FORM_DEFAULT_VALUES = {
  allSameRate: '0',
  contracts: [],
};

export const ALL_CATEGORIES_OPTIONS = [
  {
    value: ALL_DEVICE_CATEGORIES.YES,
    label: 'Yes',
  },
  {
    value: ALL_DEVICE_CATEGORIES.NO,
    label: 'No',
  },
];

export const PAYMENT_METHOD = {
  Electronic: 'electronic',
  Invoice: 'invoice',
};

export const PAYMENT_METHOD_LABELS = {
  [PAYMENT_METHOD.Invoice]: 'Invoice',
  [PAYMENT_METHOD.Electronic]: 'Electronic',
};

export const PAYMENT_METHOD_OPTIONS = [
  {
    value: PAYMENT_METHOD.Electronic,
    label: PAYMENT_METHOD_LABELS[PAYMENT_METHOD.Electronic],
  },
  {
    value: PAYMENT_METHOD.Invoice,
    label: PAYMENT_METHOD_LABELS[PAYMENT_METHOD.Invoice],
  },
];

export const SUCCESS_CREATION_MESSAGE = 'Congratulations! You successfully invited a technican.';

export const URLS = (technicianId, serviceProviderId, contractId) => ({
  view: `/app/service-providers/${technicianId}/${serviceProviderId}`,
  edit: `/app/service-providers/edit/${technicianId}/${serviceProviderId}`,
  editContract: `/app/service-providers/edit/${technicianId}/${serviceProviderId}/${contractId}`,
});

export const INITIALIZED_STATUS = 'initialized';
export const CONFIRMED_STATUS = 'confirmed';

export const CREATE_STATE_NAME = 'serviceProviderCreationState';

export const FAIL_CONNECTION_MESSAGE = 'Something went wrong while saving the technician profile data.';
export const FAIL_SERVICE_PROVIDER_GETTING_MESSAGE =
  'Something went wrong while getting the technician data.';
export const EXISTING_TECHNICIAN_MESSAGE = `This technician is already on our marketplace, but not
    connected to your facilities. Would you like to continue and invite to be your service provider?`;
export const THIS_USER_IS_FACILITY_MESSAGE = `This email is already taken in our system as a facility user. Please retry
    with a new email address or contact us via support@uptimehealth.com for assistance.`;
export const EXISTING_TECHNICIAN_IS_IN_LIST_MESSAGE =
  'This technician already exists in your service provider list';

export const MAX_CONTRACTS_COUNT = 5;
export const MAX_CONTRACTS_COUNT_WARNING = 'You can create up to 5 contracts.';
export const ALLOW_TECHNICIAN_CREATE_FACILITY =
  'Allow technician to add facilities, devices and jobs on your behalf';
export const FAIL_SAVING_SERIVCE_PROVIDER =
  'Something went wrong during saving equipments categories for the technician.';
