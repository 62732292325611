import { createTheme } from '@mui/material/styles';

import defaultTheme from './defaultTheme';
import getOverriddenStyles from './overriddenStyles';

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: '"Helvetica Neue", sans-serif',
    color: defaultTheme.palette.grey[700],
    h1: {
      fontSize: 28,
      fontWeight: 700,
      color: defaultTheme.palette.grey.black,
    },
    h2: {
      fontSize: 24,
      lineHeight: '28px',
      fontWeight: 700,
      color: defaultTheme.palette.grey.black,
    },
    // adjusted according to system design
    h3: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '24px',
      color: defaultTheme.palette.grey.black,
    },
    // adjusted according to system design
    h4: {
      fontSize: 20,
      lineHeight: '24px',
      color: defaultTheme.palette.grey.black,
    },
    // adjusted according to system design
    h5: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '20px',
      color: defaultTheme.palette.grey.black,
    },
    // adjusted according to system design
    h6: {
      fontSize: 16,
      lineHeight: '20px',
      color: defaultTheme.palette.grey.black,
    },
    // adjusted according to system design
    subtitle1: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
    },
    // adjusted according to system design
    subtitle2: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '20px',
    },
    // DEPRECATED - going to be removed soon
    caption: {
      fontSize: 14,
      lineHeight: '20px',
    },
    // adjusted according to system design
    caption1: {
      fontSize: 14,
      lineHeight: '20px',
    },
    // adjusted according to system design
    caption2: {
      fontSize: 12,
      lineHeight: '16px',
    },
    info: {
      fontSize: 12,
      color: defaultTheme.palette.grey[600],
    },
    label: {
      fontSize: 14,
      lineHeight: '20px',
      color: defaultTheme.palette.grey[800],
    },
  },
  palette: {
    ...defaultTheme.palette,
  },
  shape: {
    borderRadius: 8,
  },
  components: getOverriddenStyles(defaultTheme),
});

export const secondaryColorsWL = {
  dark: '#31323A',
  dark1: '#C0C6D0',
  main: '#E4E6EB',
  light: '#F7F8F8',
  light1: '#F7F8F8',
};

export default theme;
