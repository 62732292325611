export const SIDEBAR_WIDTH = 290;

const applicationsStyles = {
  'uptime-controller': {
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: SIDEBAR_WIDTH,
        },
      },
    },
  },
  'uptime-admin': {},
  'uptime-market': {},
  'uptime-shared': {},
  'uptime-portal': {},
};

export default applicationsStyles;
