import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" fill="none">
    <g clipPath="url(#clip0_2168_3255)">
      <circle cx="61.2768" cy="62.7768" r="60.7768" fill="#FBFAFE" />
      <circle cx="61.2767" cy="62.7767" r="47.2709" fill="#EFEEFC" />
      <path
        d="M89.529 103.448H33.1552C31.6888 103.448 30.5 102.26 30.5 100.793V24.6552C30.5 23.1888 31.6888 22 33.1552 22H77.1726L92.1842 35.8569V100.793C92.1842 102.26 90.9955 103.448 89.529 103.448Z"
        fill="#FBFAFE"
        stroke="#CECBF6"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2852 39.0239C37.2852 38.0145 38.1034 37.1963 39.1127 37.1963H74.4995C75.5089 37.1963 76.3271 38.0145 76.3271 39.0239C76.3271 40.0332 75.5089 40.8515 74.4995 40.8515H39.1127C38.1034 40.8515 37.2852 40.0332 37.2852 39.0239ZM37.2852 50.2888C37.2852 49.2794 38.1034 48.4612 39.1127 48.4612H84.7731C85.7825 48.4612 86.6007 49.2794 86.6007 50.2888C86.6007 51.2981 85.7825 52.1164 84.7731 52.1164H39.1127C38.1034 52.1164 37.2852 51.2981 37.2852 50.2888ZM37.2852 61.5537C37.2852 60.5443 38.1034 59.7261 39.1127 59.7261H51.6693C52.6787 59.7261 53.4969 60.5443 53.4969 61.5537C53.4969 62.563 52.6787 63.3813 51.6693 63.3813H39.1127C38.1034 63.3813 37.2852 62.563 37.2852 61.5537ZM57.8323 61.5537C57.8323 60.5443 58.6506 59.7261 59.6599 59.7261H84.7731C85.7825 59.7261 86.6007 60.5443 86.6007 61.5537C86.6007 62.563 85.7825 63.3813 84.7731 63.3813H59.6599C58.6506 63.3813 57.8323 62.563 57.8323 61.5537ZM37.2852 72.8186C37.2852 71.8092 38.1034 70.991 39.1127 70.991H72.2165C73.2259 70.991 74.0441 71.8092 74.0441 72.8186C74.0441 73.8279 73.2259 74.6461 72.2165 74.6461H39.1127C38.1034 74.6461 37.2852 73.8279 37.2852 72.8186ZM78.3795 72.8186C78.3795 71.8092 79.1977 70.991 80.2071 70.991H84.7731C85.7825 70.991 86.6007 71.8092 86.6007 72.8186C86.6007 73.8279 85.7825 74.6461 84.7731 74.6461H80.2071C79.1977 74.6461 78.3795 73.8279 78.3795 72.8186ZM37.2852 82.957C37.2852 81.9476 38.1034 81.1294 39.1127 81.1294H81.3486C82.3579 81.1294 83.1762 81.9476 83.1762 82.957C83.1762 83.9663 82.3579 84.7846 81.3486 84.7846H39.1127C38.1034 84.7846 37.2852 83.9663 37.2852 82.957ZM37.2852 93.0954C37.2852 92.086 38.1034 91.2678 39.1127 91.2678H69.9335C70.9428 91.2678 71.7611 92.086 71.7611 93.0954C71.7611 94.1047 70.9428 94.923 69.9335 94.923H39.1127C38.1034 94.923 37.2852 94.1047 37.2852 93.0954Z"
        fill="#9E96ED"
      />
      <path
        d="M37.2852 39.0239C37.2852 38.0145 38.1034 37.1963 39.1127 37.1963H74.4995C75.5089 37.1963 76.3271 38.0145 76.3271 39.0239C76.3271 40.0332 75.5089 40.8515 74.4995 40.8515H39.1127C38.1034 40.8515 37.2852 40.0332 37.2852 39.0239Z"
        fill="#695EE4"
      />
      <path
        d="M78.0645 31.4191V22.142L91.9063 34.9191H81.5645C79.6315 34.9191 78.0645 33.3521 78.0645 31.4191Z"
        fill="#CECBF6"
        stroke="#CECBF6"
      />
      <rect
        width="14.7292"
        height="2.53951"
        rx="1.26976"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 20.916 23)"
        fill="#695EE4"
      />
      <rect
        width="14.7292"
        height="2.53951"
        rx="1.26976"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 10.5 24.7959)"
        fill="#695EE4"
      />
      <circle cx="26.8412" cy="58.3412" r="4.3412" fill="#EFEEFC" stroke="#9E96ED" strokeWidth="4" />
      <circle cx="117.108" cy="71.6078" r="3.60776" fill="#9E96ED" stroke="#695EE4" strokeWidth="4" />
      <circle cx="10.6101" cy="79.1101" r="1.11008" fill="#EFEEFC" stroke="#9E96ED" strokeWidth="3" />
      <path
        d="M63.4154 125.958C85.2875 125.958 103.018 124.923 103.018 123.646C103.018 122.369 85.2875 121.334 63.4154 121.334C41.5433 121.334 23.8125 122.369 23.8125 123.646C23.8125 124.923 41.5433 125.958 63.4154 125.958Z"
        fill="#CECBF6"
      />
      <path
        d="M84.2391 89.7785L90.1896 83.8279L114.71 104.757C117.714 107.32 117.894 111.899 115.102 114.691C112.31 117.483 107.731 117.302 105.168 114.299L84.2391 89.7785Z"
        fill="#5449D2"
      />
      <path
        d="M88.4648 94.7286L105.169 114.3C107.732 117.303 112.311 117.483 115.103 114.691C117.895 111.9 117.715 107.32 114.711 104.757L92.8644 86.1104C91.9853 89.2798 90.4722 92.1977 88.4648 94.7286Z"
        fill="#695EE4"
      />
      <path
        d="M77.445 54.0706L62.0644 89.7789C61.8275 89.5956 61.5928 89.4059 61.3624 89.2139C59.8758 87.9567 58.5812 86.4797 57.5215 84.8316L70.8066 53.9877C73.0076 53.6254 75.2533 53.6534 77.445 54.0706Z"
        fill="white"
      />
      <path
        d="M88.3781 60.2656L73.9172 93.8383C70.9135 93.8416 67.9493 93.1385 65.2539 91.7835L81.0442 55.1221C83.8416 56.2404 86.3506 58.0001 88.3781 60.2656Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.1275 59.0059C62.3273 60.8284 60.1092 63.4653 58.763 66.5903C57.4166 69.716 57.0059 73.1834 57.5859 76.5518C58.1658 79.9199 59.7086 83.0286 62.0098 85.4876C64.3104 87.9458 67.2648 89.6439 70.4956 90.3772C73.7259 91.1104 77.0965 90.8485 80.184 89.6223L80.1843 89.6222C82.2353 88.8079 84.1133 87.587 85.7095 86.026C87.3059 84.465 88.5891 82.5944 89.4829 80.5194C90.3768 78.4444 90.8628 76.2074 90.9118 73.9367C90.9607 71.666 90.5715 69.4089 89.7676 67.2949L89.7675 67.2947C88.5572 64.1107 86.4542 61.375 83.734 59.4269C81.0147 57.4794 77.7984 56.4043 74.4919 56.3296C71.1855 56.255 67.9269 57.1839 65.1275 59.0059ZM53.5885 64.3613C55.3683 60.2295 58.3111 56.7199 62.054 54.2838C65.7977 51.8472 70.1706 50.5964 74.6191 50.6969C79.0675 50.7973 83.3811 52.2441 87.0146 54.8463C90.6473 57.448 93.4348 61.0856 95.034 65.2926C96.0963 68.0863 96.6092 71.0646 96.5447 74.0581C96.4802 77.0517 95.8394 80.0045 94.6574 82.7484C93.4754 85.4925 91.7744 87.9756 89.6487 90.0543C87.5229 92.1331 85.014 93.7667 82.2636 94.8587C78.1208 96.5039 73.5906 96.8572 69.2485 95.8717C64.9069 94.8863 60.9576 92.6088 57.8961 89.3375C54.8351 86.0668 52.7981 81.9491 52.0334 77.5079C51.2687 73.0669 51.8088 68.4927 53.5885 64.3613Z"
        fill="#695EE4"
      />
      <path
        d="M112.511 104.953L94.8447 90.4862C94.2971 90.0378 93.6591 90.6684 94.1025 91.2199L108.368 108.963C108.919 109.648 109.714 110.093 110.541 110.179L111.031 110.23C112.723 110.407 113.996 109.07 113.746 107.379L113.688 106.985C113.574 106.209 113.148 105.474 112.511 104.953Z"
        fill="#9E96ED"
      />
      <rect
        width="22.9185"
        height="3.95147"
        rx="1.97574"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 80.7051 64)"
        fill="#695EE4"
      />
      <rect
        width="22.9185"
        height="3.95147"
        rx="1.97574"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 64.5 66.7939)"
        fill="#695EE4"
      />
      <rect
        width="12.8004"
        height="2.20696"
        rx="1.10348"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 111.551 39)"
        fill="#9E96ED"
      />
      <rect
        width="12.8004"
        height="2.20696"
        rx="1.10348"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 102.5 40.5605)"
        fill="#9E96ED"
      />
      <rect
        width="10.8561"
        height="1.87174"
        rx="0.935869"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 45.1758 109)"
        fill="#695EE4"
      />
      <rect
        width="10.8561"
        height="1.87174"
        rx="0.935869"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 37.5 110.323)"
        fill="#695EE4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2168_3255">
        <rect width="128" height="128" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
