import isString from 'lodash/isString';
import { IMAGE_MIMES } from '../constants';
import theme from '../theme/muiTheme';

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export const cropImage = async (imageSrc, type, pixelCrop) => {
  const image = await createImage(imageSrc);

  const canvas = document.createElement('canvas');

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  const ctx = canvas.getContext('2d');

  ctx.fillStyle = theme.palette.yellow.dark;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, type);
  });
};

export const rotateImage = async (imageSrc, type, degree) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');

  const ctx = canvas.getContext('2d');
  let cw = image.width,
    ch = image.height,
    cx = 0,
    cy = 0;

  switch (degree) {
    case 90:
      cw = image.height;
      ch = image.width;
      cy = image.height * -1;
      break;
    case 180:
      cx = image.width * -1;
      cy = image.height * -1;
      break;
    case 270:
      cw = image.height;
      ch = image.width;
      cx = image.width * -1;
      break;
    default:
      break;
  }

  canvas.setAttribute('width', cw);
  canvas.setAttribute('height', ch);

  ctx.rotate((degree * Math.PI) / 180);
  ctx.drawImage(image, cx, cy);

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, type);
  });
};

export const readImage = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });

export const getImageBlob = async (imageUrl) => {
  const response = await fetch(imageUrl, { mode: 'no-cors' });
  return response.blob();
};

export const getAvatarPlaceholder = (text = '') => {
  if (!text) return '';
  const stringified = isString(text) ? text : `${text}`;
  const parts = stringified.trim().split(' ');
  return (parts.length > 1 ? `${parts[0][0] || ''}${parts[1][0] || ''}` : stringified[0]).toUpperCase();
};

export const checkIsFileImage = (file) => Boolean(IMAGE_MIMES.find((item) => item === file?.type));

export const checkUrlOnImage = (url) =>
  new Promise((resolve) => {
    const img = new Image();
    const handleReject = () => {
      resolve(false);
    };

    img.onerror = handleReject;
    img.onabort = handleReject;
    img.onload = () => {
      resolve(true);
    };
    img.src = url;
  });
