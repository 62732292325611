import React from 'react';

const CooperationIcon = () => {
  return (
    <svg width="230" height="230" viewBox="0 0 240 230" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M80.8676 108.268H80.8714V110.669L80.8676 108.268Z" fill="url(#paint0_linear)" />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M240 111.549H126.504V150.892H240V111.549Z" fill="url(#paint1_linear)" />
      </g>
      <path
        d="M171.15 81.8725L143.41 65.9707L127.651 75.0852L155.391 90.9832L171.15 81.8725Z"
        fill="#A22D2D"
      />
      <path
        d="M143.408 69.7682L140.109 67.8809L128.486 61.2155L128.413 61.1717C127.944 60.8965 127.429 60.71 126.892 60.6213C125.278 60.3375 123.279 60.7774 121.085 62.0401C118.891 63.3027 116.902 65.1823 115.278 67.3381C112.926 70.4652 111.403 74.1559 111.403 77.3325C111.403 80.1567 112.603 82.0002 114.525 82.6649L112.35 83.9237L98.766 91.7813V104.718V106.897C100.531 103.958 103.067 101.286 105.881 99.6598C107.334 98.8181 108.718 98.3382 109.956 98.2029C111.414 98.0354 112.673 98.3382 113.626 99.0599C114.328 99.6028 114.856 100.339 115.147 101.178C115.435 102.007 115.575 102.882 115.561 103.76C115.561 105.944 114.845 108.374 113.624 110.698C112.989 111.911 112.243 113.061 111.395 114.135L114.609 115.979L123.31 120.966L126.508 119.117L139.569 111.56L140.037 111.29C141.898 110.212 143.406 107.83 143.406 105.971V90.1644L144.376 90.4291L156.279 77.1535L143.408 69.7682Z"
        fill="#E33E3A"
      />
      <path
        d="M140.535 136.171C130.92 142.473 126.506 135.95 126.506 135.95L111.888 127.571L98.7679 120.047C100.532 120.942 103.067 120.683 105.883 119.056C106.6 118.639 107.285 118.169 107.933 117.651C109.22 116.617 110.383 115.437 111.399 114.135L114.613 115.977L123.314 120.964L126.512 122.797V119.115L139.573 111.559L148.829 121.25C148.829 121.25 150.156 129.875 140.535 136.171Z"
        fill="#A32D2D"
      />
      <path
        d="M139.571 111.56L126.51 119.119V122.8L123.314 120.966L114.611 115.979L124.367 113.76L126.51 113.436L139.491 111.475L139.571 111.56Z"
        fill="#EDBC40"
      />
      <path
        d="M171.149 121.873C171.149 123.731 169.641 126.116 167.777 127.194L126.502 151.063V135.948C128.271 136.845 130.804 136.582 133.62 134.954C136.791 133.12 139.602 129.97 141.366 126.596C142.583 124.27 143.304 121.834 143.304 119.658C143.304 117.481 142.583 115.879 141.366 114.958C139.602 113.625 136.791 113.724 133.62 115.556C130.804 117.186 128.271 119.856 126.502 122.792V107.689L140.088 99.8309L142.262 98.574C140.343 97.9113 139.141 96.0659 139.141 93.2417C139.141 90.067 140.663 86.3743 143.017 83.2435C144.637 81.0877 146.645 79.2061 148.824 77.9454C151.003 76.6847 153.007 76.2448 154.633 76.5285C156.983 76.9361 158.506 78.8691 158.506 82.0513C158.506 84.8718 157.302 88.1093 155.385 90.9868L157.563 89.7299L168.893 83.1787L170.034 82.5122L171.143 81.8723L171.149 121.873Z"
        fill="#EF6B6B"
      />
      <path
        d="M126.856 136.484C127.476 136.706 128.131 136.816 128.79 136.809C130.311 136.809 132.047 136.274 133.8 135.261C136.93 133.45 139.803 130.351 141.68 126.76C142.975 124.284 143.657 121.833 143.657 119.66C143.657 117.43 142.94 115.708 141.581 114.68C140.673 114.022 139.575 113.68 138.455 113.705C136.934 113.705 135.2 114.242 133.448 115.255C131.013 116.664 128.655 118.94 126.856 121.599V107.887L143.104 98.4868L142.38 98.2411C140.52 97.6012 139.495 95.8263 139.495 93.2458C139.495 90.2388 140.883 86.6718 143.298 83.4591C144.956 81.2538 146.928 79.4598 149.002 78.2543C150.647 77.3021 152.256 76.7993 153.653 76.7993C153.962 76.7986 154.271 76.8248 154.575 76.8774C156.857 77.2735 158.16 79.1627 158.16 82.0479C158.16 84.6817 157.044 87.8659 155.098 90.7891L155.566 91.2881L156.865 90.5263L157.739 90.0255L169.072 83.4724L170.799 82.4707V121.863C170.799 123.61 169.365 125.863 167.604 126.88L126.854 150.445L126.856 136.484Z"
        fill="url(#paint2_linear)"
      />
      <path d="M126.508 151.064L98.766 135.164V120.05L126.508 135.95V151.064Z" fill="#E33E3A" />
      <path
        d="M126.51 107.69V122.8L123.314 120.966L114.611 115.978L111.397 114.137L98.766 106.898V91.7886L109.954 98.2045L115.149 101.179L126.51 107.69Z"
        fill="#E33E3A"
      />
      <path
        d="M98.766 91.7888L126.51 107.691L142.265 98.5742L114.523 82.6743L98.766 91.7888Z"
        fill="#A32D2D"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M168.977 152.06H55.4814V189.329H168.977V152.06Z" fill="url(#paint3_linear)" />
      </g>
      <path
        d="M113.173 128.902L96.4215 139.677L90.2912 137.583C90.2912 137.583 80.5938 132.462 80.4151 132.326C80.3522 132.077 80.3165 131.822 80.3086 131.565C80.7859 131.165 81.2593 130.738 81.7024 130.285L82.8432 130.938L98.612 121.827L87.2508 115.316L85.0128 114.048L82.0484 112.349C83.5068 112.183 84.7656 112.484 85.7163 113.206L113.173 128.902Z"
        fill="#99CAFF"
      />
      <path
        d="M72.8872 155.052C67.0688 154.823 64.2813 154.328 63.1328 153.766C60.9385 152.7 64.7376 151.422 64.7376 151.422L54.1351 134.615L54.3462 130.351C56.6926 127.226 58.2156 123.539 58.2156 120.365C58.2156 117.54 57.0101 115.697 55.0935 115.032L56.5842 115.2L81.7176 130.266L82.8318 130.934C82.8318 130.934 85.9806 130.725 87.0569 133.655C88.1331 136.586 89.8387 155.718 72.8872 155.052Z"
        fill="#A7C1BC"
      />
      <path
        d="M27.0185 173.475C26.5127 173.184 26.2066 172.589 26.2066 171.761V131.759L53.9488 147.659L54.1922 158.95L54.8367 189.421L27.0185 173.475Z"
        fill="#99CAFF"
      />
      <path
        d="M115.398 133.802C115.398 135.988 114.677 138.415 113.46 140.74C111.696 144.114 108.885 147.264 105.716 149.098C102.9 150.725 100.367 150.987 98.5986 150.092V152.265V162.044V165.208L67.3464 183.273L57.3143 189.081C56.4796 189.565 55.7171 189.7 55.1315 189.538C54.4051 189.348 53.9469 188.683 53.9469 187.655V147.662L63.3705 142.214L69.7118 138.548C67.7932 141.427 66.5896 144.665 66.5896 147.485C66.5896 150.66 68.1108 152.593 70.4629 153.008C72.081 153.29 74.0927 152.85 76.2699 151.589C78.4471 150.329 80.4531 148.445 82.0826 146.289C84.4423 143.139 85.9635 139.445 85.9635 136.27C85.9635 133.448 84.7599 131.604 82.8413 130.938L85.0147 129.683L92.4893 125.364L98.6062 121.825V134.764V136.942C100.375 134.002 102.907 131.33 105.723 129.706C108.893 127.874 111.703 127.775 113.468 129.108C114.675 130.022 115.398 131.618 115.398 133.802Z"
        fill="#D0E7FF"
      />
      <path
        d="M55.6354 189.245C55.4967 189.246 55.3584 189.228 55.2247 189.192C54.6257 189.028 54.2968 188.479 54.2968 187.647V147.845L63.7165 142.402L68.6603 139.546C67.1011 142.238 66.2246 145.068 66.2246 147.477C66.2246 150.696 67.7818 152.888 70.3887 153.339C70.733 153.398 71.0815 153.427 71.4307 153.427C72.9519 153.427 74.6822 152.892 76.4334 151.879C78.5859 150.635 80.6318 148.771 82.3489 146.487C84.855 143.153 86.2944 139.441 86.2944 136.279C86.2944 133.729 85.3208 131.816 83.6 130.907L98.2412 122.448V136.001V138.22L98.8915 137.138C100.694 134.143 103.242 131.549 105.885 130.02C107.53 129.067 109.14 128.567 110.542 128.567C111.509 128.536 112.46 128.828 113.244 129.397C114.419 130.288 115.042 131.817 115.042 133.819C115.042 135.88 114.384 138.222 113.141 140.593C111.323 144.069 108.549 147.068 105.535 148.811C103.887 149.763 102.276 150.266 100.873 150.266C100.138 150.279 99.4111 150.117 98.7508 149.795L98.2412 149.538V165.019L57.1337 188.786C56.6812 189.064 56.1656 189.222 55.6354 189.245Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M69.7118 138.531L53.9469 147.651L26.2047 131.749L41.9677 122.629L69.7118 138.531Z"
        fill="#58A9FF"
      />
      <path
        d="M98.6005 121.831L82.8337 130.939L81.6966 130.286L80.147 129.399L56.4777 115.832L55.0934 115.039L70.8584 105.929L82.0465 112.343L85.0128 114.042L87.2394 115.319L98.6005 121.831Z"
        fill="#A7C1BC"
      />
      <path
        d="M157.565 73.8473L129.822 57.9455C129.822 57.9455 142.777 50.8172 146.935 38.5566L168.296 47.854L172.004 58.1378L168.705 69.6595L157.565 73.8473Z"
        fill="#014473"
      />
      <path
        d="M123.899 14.2731C123.881 14.2617 123.861 14.2522 123.84 14.2446L123.899 14.2731Z"
        fill="#A7C1BC"
      />
      <path
        d="M164.474 15.9018L154.039 34.6489L150.573 32.6588L126.295 18.747L127.603 16.3951L136.736 0L164.474 15.9018Z"
        fill="#014473"
      />
      <path
        d="M135.261 68.3682L107.518 52.4682L117.957 33.7212L145.699 49.6211L135.261 68.3682Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M152.088 30.4691L137.447 51.5718L136.418 50.9853L123.787 43.7485L115.118 38.7971L109.353 35.4986C107.653 34.5464 106.836 32.5601 106.948 30.0425C107.035 27.9629 107.756 25.5309 109.144 23.0457C110.471 20.6576 112.229 18.5365 114.327 16.7897C117.18 14.4434 120.321 13.236 122.77 13.8397C123.139 13.9309 123.496 14.0685 123.831 14.2492C123.851 14.2567 123.871 14.2663 123.89 14.2778L127.605 16.3955L152.088 30.4691Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M196.818 28.2178L194.217 32.8836L169.734 76.8356L167.994 76.409L159.247 74.2627L157.566 73.8513C160.169 72.1945 162.712 69.5321 164.499 66.3194C166.515 62.701 167.123 59.2064 166.429 56.7478C165.952 55.0529 164.86 53.8493 163.176 53.4361C161.491 53.0228 159.485 53.4627 157.458 54.5425C154.52 56.1098 151.564 59.0274 149.546 62.6439C147.757 65.8566 147.074 68.978 147.432 71.3585L139.765 69.475L135.26 68.3724L145.701 49.6253C143.117 51.4764 140.394 52.3696 138.219 51.8363C135.77 51.2346 134.568 48.9626 134.7 45.946C134.789 43.8645 135.51 41.4344 136.896 38.9492C138.222 36.5589 139.98 34.4363 142.081 32.6894C144.933 30.3432 148.074 29.1396 150.523 29.7414C152.699 30.2746 153.891 32.1314 154.039 34.6528L163.141 18.3092L164.472 15.9077L196.348 23.7425C197.792 24.0872 197.997 26.0944 196.818 28.2178Z"
        fill="#418BBF"
      />
      <path
        d="M160.008 74.0893L159.329 73.9179L158.422 73.6951C160.911 71.9411 163.208 69.3531 164.803 66.4869C166.802 62.899 167.517 59.313 166.766 56.6487C166.231 54.7443 165.018 53.5236 163.256 53.0913C162.797 52.9806 162.326 52.9256 161.854 52.9275C160.466 52.9275 158.888 53.3788 157.291 54.2301C154.159 55.9003 151.223 58.9035 149.238 62.4686C147.603 65.4052 146.81 68.4408 147.021 70.8937L135.782 68.138L145.996 49.7928L145.487 49.3358C143.42 50.8174 141.303 51.6344 139.527 51.6344C139.112 51.6367 138.697 51.5887 138.293 51.4916C136.097 50.9507 134.911 48.934 135.04 45.9574C135.131 43.8435 135.877 41.4782 137.192 39.1148C138.496 36.7626 140.226 34.6746 142.294 32.9578C144.667 31.0077 147.118 29.9336 149.192 29.9336C149.609 29.9321 150.025 29.9807 150.43 30.0784C152.381 30.5564 153.535 32.1885 153.68 34.6699L153.748 35.8735L163.661 18.0749L164.646 16.3057L196.261 24.0776C196.441 24.1186 196.608 24.2027 196.749 24.3228C196.889 24.4429 196.999 24.5953 197.067 24.767C197.409 25.5516 197.193 26.8066 196.497 28.0464L193.896 32.7122L169.557 76.4317L160.008 74.0893Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M197.008 24.087L166.747 16.4694L164.465 15.898L136.732 0L168.602 7.83665C168.794 7.88157 168.975 7.96375 169.135 8.07851L197.008 24.087Z"
        fill="url(#paint8_linear)"
      />
      <path d="M115.651 116.596L115.655 116.594V119.528L115.651 116.596Z" fill="url(#paint9_linear)" />
      <path
        d="M76.2167 138.455L75.9999 147.832L72.7674 153.035L71.9821 154.299L68.9569 152.549C68.9071 152.529 68.8599 152.504 68.8162 152.473C67.4186 151.603 66.5763 149.929 66.5763 147.571C66.5763 145.051 67.5403 142.195 69.1223 139.561L70.6739 138.455H76.2167Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M101.816 107.335L74.0737 91.4346L67.2837 112.315L81.4799 120.399L93.1757 121.591L101.816 107.335Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M74.0699 61.8325L32.7913 85.7024C30.9317 86.7784 29.4239 89.1608 29.422 91.0348V133.718L57.1641 149.62L101.812 77.7344L74.0699 61.8325Z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M86.9484 103.541C85.7315 105.866 85.0128 108.302 85.0128 110.479C85.0128 112.656 85.7315 114.257 86.9484 115.177C88.7149 116.51 91.5252 116.413 94.6931 114.579C97.511 112.951 100.048 110.277 101.816 107.342V117.245V119.645V123.796L95.6989 127.335L86.051 132.901C87.9695 133.564 89.1731 135.408 89.1731 138.234C89.1731 141.408 87.652 145.105 85.2999 148.232C83.678 150.388 81.6681 152.271 79.4872 153.532C77.3062 154.793 75.304 155.233 73.6801 154.949C71.328 154.541 69.8069 152.608 69.8069 149.426C69.8069 146.606 71.0105 143.368 72.9291 140.489L66.5877 144.155L57.1641 149.603V106.929C57.1641 105.063 58.6739 102.678 60.5354 101.597L101.812 77.7344V79.8426V94.1923C100.044 93.2934 97.5072 93.5524 94.6893 95.1807C91.5252 97.0146 88.7149 100.166 86.9484 103.541Z"
        fill="#99CAFF"
      />
      <path
        d="M74.6613 154.678C74.3529 154.679 74.045 154.654 73.741 154.602C71.4592 154.206 70.1568 152.317 70.1568 149.432C70.1568 146.8 71.2729 143.612 73.22 140.688L72.7522 140.189L66.236 143.956L57.514 148.999V106.929C57.514 105.175 58.9496 102.93 60.7084 101.907L101.46 78.3418V80.3624V93.6571C100.839 93.4348 100.184 93.3246 99.5247 93.3314C98.0035 93.3314 96.2675 93.8666 94.5143 94.8797C91.3845 96.6889 88.5134 99.7874 86.6366 103.381C85.3417 105.857 84.6572 108.306 84.6572 110.481C84.6572 112.711 85.376 114.432 86.7336 115.459C87.6414 116.117 88.7393 116.459 89.8596 116.434C91.3807 116.434 93.1149 115.897 94.8661 114.886C97.3038 113.474 99.6616 111.199 101.46 108.536V117.003V117.243V123.593L96.0412 126.727L95.5221 127.024L85.2029 132.987L85.9312 133.238C87.7927 133.88 88.8176 135.653 88.8176 138.232C88.8176 141.191 87.3953 144.85 85.0147 148.019C83.3547 150.226 81.381 152.018 79.3103 153.225C77.6694 154.175 76.0608 154.678 74.6613 154.678Z"
        fill="url(#paint13_linear)"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M3.4006 200.839C3.4006 200.839 76.3973 202.06 78.7551 199.438C80.5139 197.478 37.0087 189.535 38.4824 189.453C44.0346 189.154 8.12951 195.324 4.39316 196.429C4.39316 196.429 1.36224 197.688 1.36224 199.144C1.36224 200.508 3.4006 200.839 3.4006 200.839Z"
          fill="url(#paint14_linear)"
        />
      </g>
      <path
        d="M41.8061 146.67C41.3346 146.352 40.6101 147.253 40.6348 146.994C40.6595 146.735 42.0039 143.821 41.6692 143.635C41.3346 143.448 41.0512 143.825 41.0512 143.825C41.0512 143.825 41.2794 143.002 40.9923 142.85C40.7052 142.698 40.4428 142.917 40.2736 143.269C40.2736 143.269 39.9997 142.631 39.4749 143.562C39.4749 143.562 39.3 143.301 38.8874 143.926C38.4748 144.551 37.3948 146.51 37.8644 148.396C37.8644 148.396 37.9728 148.46 38.1287 148.548L38.1706 148.824C38.1706 148.824 37.3719 150.091 38.3911 149.822C38.8342 149.71 39.2161 149.429 39.4559 149.039C39.4787 149.041 39.5016 149.041 39.5244 149.039C39.5244 149.039 40.401 148.824 40.7603 148.312C41.1197 147.8 42.2815 146.994 41.8061 146.67Z"
        fill="#F3B698"
      />
      <path
        d="M39.6194 149.203C39.6194 149.203 36.8642 151.736 34.4304 153.37C33.377 154.076 32.3863 154.61 31.7037 154.703C30.1502 154.907 25.176 154.876 22.5767 153.547C21.3997 152.941 20.7114 152.075 21.029 150.841C21.0783 150.661 21.1587 150.49 21.2666 150.338C22.2364 148.961 25.3567 148.613 25.3567 148.613C25.3567 148.613 27.8076 149.755 29.5665 150.369C30.3613 150.643 31.0059 150.81 31.2283 150.721C31.9376 150.422 37.6666 147.946 37.6666 147.946C37.6666 147.946 39.8001 147.544 39.6194 149.203Z"
        fill="#F55551"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M34.4342 153.37C33.3808 154.076 32.3901 154.609 31.7075 154.703C30.154 154.906 25.1798 154.876 22.5805 153.547L21.2704 151.349V150.338C22.2402 148.961 25.3605 148.612 25.3605 148.612C25.3605 148.612 27.8114 149.755 29.5703 150.368C30.8614 151.374 32.8161 152.777 34.4342 153.37Z"
          fill="#D3EAE9"
        />
      </g>
      <path
        d="M33.32 190.091C33.32 190.091 36.1379 189.849 37.0088 189.533C37.815 189.241 38.6421 188.805 39.8609 189.489C40.9467 190.093 40.5227 191.734 37.0734 193.328C34.7651 194.393 32.4111 195.08 31.7684 194.181C31.4889 193.789 31.3729 191.801 31.6467 191.22C31.9015 190.685 33.32 190.091 33.32 190.091Z"
        fill="#0F152B"
      />
      <path
        d="M23.0787 173.696C23.0787 173.696 15.1858 184.149 14.9158 184.962C14.6458 185.775 4.58143 194.604 4.58143 194.604C4.02563 194.5 3.49018 194.307 2.99562 194.033C1.28431 193.018 2.23504 191.45 2.23504 191.45C3.48429 189.199 10.6927 182.271 10.6927 182.271C11.9476 175.966 14.9196 169.919 14.9196 169.919C18.481 168.11 20.9795 170.53 22.2079 172.254C22.5407 172.707 22.8321 173.19 23.0787 173.696Z"
        fill="#58A9FF"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M23.0787 173.696C23.0787 173.696 15.1858 184.149 14.9158 184.963C14.6458 185.776 4.58141 194.605 4.58141 194.605C4.02562 194.501 3.49016 194.308 2.99561 194.033C6.57033 191.984 11.6719 185.083 13.8871 183.226C16.4712 181.076 18.1692 174.288 18.1692 174.288L22.2117 172.254C22.5432 172.708 22.8333 173.19 23.0787 173.696Z"
          fill="#BCBEC0"
        />
      </g>
      <path
        d="M35.1358 191.176C34.5013 191.664 33.733 191.946 32.934 191.984C32.5893 192.009 32.2428 191.995 31.9015 191.94C31.2189 191.001 30.6066 181.237 30.5457 180.26C30.5457 180.209 30.5457 180.182 30.5457 180.182C30.5457 180.182 25.9823 180.479 21.2686 178.975C16.2107 177.352 18.0133 172.248 18.0133 172.248C17.8231 171.534 19.7702 171.839 23.0845 172.237C26.3987 172.635 34.1509 175.364 34.959 176.084C35.7671 176.803 35.1358 191.176 35.1358 191.176Z"
        fill="#58A9FF"
      />
      <path
        d="M48.9556 148.824C48.9689 148.077 48.678 148.192 48.678 148.192C48.7711 147.129 48.1855 147.498 48.1855 147.498C48.2444 147.118 48.1532 146.782 47.8299 146.737C47.5067 146.691 47.2291 147.498 47.2291 147.498C47.2291 147.498 47.2043 147.036 46.824 146.998C46.4438 146.96 45.8999 150.121 45.7745 150.351C45.649 150.582 45.5615 149.426 44.9911 149.42C44.4206 149.414 44.9245 150.732 44.9321 151.357C44.9685 151.735 45.1094 152.095 45.339 152.397L45.3143 152.416L44.4777 153.705C44.4777 153.705 44.2989 154.301 45.4037 154.276C46.5084 154.252 47.269 153.069 47.269 153.069C48.7198 151.801 48.9404 149.572 48.9556 148.824Z"
        fill="#F3B698"
      />
      <path
        d="M31.2435 154.562V154.728C31.2435 158.902 28.129 168.931 26.7504 172.606C26.7373 172.633 26.7265 172.661 26.7181 172.69C26.6858 172.78 26.6553 172.861 26.623 172.94L26.5336 172.97C25.0481 173.678 23.3923 173.949 21.7591 173.751C17.9372 173.284 15.1801 170.439 14.9139 169.91L15.163 169.148L15.8646 166.981L17.2812 162.601L20.3901 151.896C22.1185 147.352 25.4574 148.298 25.9043 148.38C25.9195 148.382 25.9343 148.387 25.948 148.394C26.3055 148.54 27.9711 149.793 29.4067 151.045C30.3251 151.852 30.5476 152.72 30.9279 153.244C30.9526 153.29 30.9907 153.339 31.0173 153.391C31.1599 153.629 31.2359 154.019 31.2435 154.562Z"
        fill="#F55551"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M31.2436 154.562V154.728C31.2302 158.398 28.4161 167.762 26.7504 172.607C26.7373 172.633 26.7265 172.662 26.7181 172.69C26.6858 172.78 26.6554 172.862 26.623 172.94L26.5337 172.97C25.0482 173.678 23.3924 173.949 21.7591 173.751C22.7308 171.668 24.2804 168.885 24.925 167.716C25.9404 165.85 27.8038 158.175 27.9826 155.413C28.11 153.431 30.1198 154.058 31.2436 154.562Z"
          fill="#D3EAE9"
        />
      </g>
      <path
        d="M29.9696 151.91C29.9696 151.91 37.6667 155.454 38.412 155.147C39.1574 154.841 45.1565 152.249 45.1565 152.249C45.1565 152.249 47.3831 151.83 47.1967 153.567C47.1967 153.567 41.2775 159.004 38.914 159.316C36.5505 159.628 27.6954 159.564 27.7411 155.273C27.7867 150.982 29.9696 151.91 29.9696 151.91Z"
        fill="#F55551"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M29.4087 151.054C28.3629 151.216 26.2104 150.268 25.7693 149.721C25.3281 149.175 25.9024 148.388 25.9024 148.388C26.1781 148.436 27.9065 149.744 29.4087 151.054Z"
          fill="#BCBEC0"
        />
      </g>
      <path
        d="M32.7609 142.339L32.3597 142.362C32.3978 142.277 32.4282 142.214 32.4282 142.214L29.7414 141.351L26.258 146.857L25.8777 148.845C26.0678 149.902 29.1938 150.799 29.2661 150.846C29.3383 150.894 29.6825 148.908 29.789 148.717C31.2797 149.02 32.1943 148.643 32.6792 146.112C33.3637 142.578 32.7609 142.339 32.7609 142.339Z"
        fill="#F3B698"
      />
      <path
        d="M31.0877 146.722C31.0877 146.722 30.6085 146.994 30.4944 146.739C30.3803 146.483 30.2548 145.695 29.6939 145.842C29.133 145.988 27.6841 148.363 27.203 148.46C26.7219 148.557 26.3093 148.879 25.6609 147.977C24.9517 146.986 24.058 145.051 24.6227 142.237C25.2141 139.294 27.3171 138.306 28.8801 138.607C33.7155 139.532 33.5424 141.014 33.2895 142.035C32.9872 143.252 31.6429 142.81 31.6429 142.81C31.6429 142.81 32.1106 143.991 31.852 144.381C31.5934 144.771 30.9241 145.28 31.0877 146.722Z"
        fill="#0064AA"
      />
      <path
        d="M0.508514 192.862L2.43279 191.546C2.43279 191.546 3.52992 190.784 4.686 192.715C5.31538 193.76 4.11557 194.795 4.11557 194.795C4.11557 194.795 3.56225 198.002 3.71246 198.333C3.86267 198.664 5.11954 199.262 4.82101 199.803C4.52248 200.344 3.65161 201.033 3.08308 200.793C2.51455 200.553 1.06754 197.703 0.949652 197.07C0.831762 196.438 0.508514 192.862 0.508514 192.862Z"
        fill="#10122B"
      />
      <path
        d="M2.81497 198.603C2.81497 198.603 2.81497 193.604 2.52215 193.178C2.22932 192.751 0.499005 191.917 0.139631 193.604C-0.219743 195.292 0.177658 199.127 0.618794 199.546C1.05993 199.965 2.95948 200.971 3.40062 200.839C3.01396 200.158 2.81205 199.387 2.81497 198.603Z"
        fill="#21274F"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M32.9339 191.982C32.5892 192.008 32.2427 191.993 31.9015 191.938C31.2188 190.999 30.6066 181.235 30.5457 180.259C30.8766 179.821 31.6866 178.794 32.2095 178.529C32.2095 178.529 31.5098 183.616 32.0365 187.44C32.3274 189.55 32.6259 190.999 32.9339 191.982Z"
          fill="#BCBEC0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M195.677 121.048C195.677 121.048 229.713 119.334 231.904 116.897C234.094 114.459 204.418 109.247 190.684 113.303C190.684 113.303 186.157 114.826 186.202 116.188C186.259 118.134 195.677 121.048 195.677 121.048Z"
          fill="url(#paint15_linear)"
        />
      </g>
      <path
        d="M190.517 110.382L190.477 111.408C190.466 111.516 190.431 111.621 190.374 111.713C190.317 111.805 190.24 111.884 190.148 111.941C189.271 112.431 186.815 113.808 186.282 114.208C185.63 114.697 185.533 115.838 185.83 116.112C186.195 116.451 186.436 116.811 187.731 116.683C188.611 116.6 190.1 115.941 191.403 115.367C192.231 115.034 193.004 114.578 193.698 114.015C193.865 113.86 193.974 113.651 194.008 113.425C194.109 112.459 194.367 109.807 194.234 109.02C194.101 108.233 191.713 109.401 190.838 109.848C190.746 109.906 190.669 109.984 190.613 110.076C190.558 110.169 190.524 110.274 190.517 110.382Z"
        fill="#0F152B"
      />
      <path
        d="M196.98 86.0239C196.923 88.3092 196.647 90.4612 196.647 90.4612C196.647 90.4612 194.652 110.958 194.341 111.219C194.075 111.428 193.767 111.574 193.438 111.649C193.109 111.724 192.768 111.725 192.439 111.652C191.273 111.461 190.258 110.656 190.258 110.656L189.853 78.5605C189.853 78.5605 194.255 79.5432 195.999 80.724C196.877 81.3086 197.039 83.7386 196.98 86.0239Z"
        fill="#0064AA"
      />
      <path
        d="M196.98 86.0243C196.923 88.3096 196.647 90.4616 196.647 90.4616C196.647 90.4616 194.652 110.959 194.34 111.22C194.075 111.428 193.767 111.575 193.438 111.65C193.109 111.724 192.768 111.725 192.439 111.652C192.688 110.7 193.163 108.022 193.373 106.335C193.723 103.478 193.884 90.7949 194.124 89.8979C194.363 89.0009 195.732 86.6471 195.732 86.6471C195.732 86.6471 196.367 85.952 196.98 86.0243Z"
        fill="#014473"
      />
      <path
        d="M198.459 114.684V115.108C198.448 115.335 198.357 115.551 198.202 115.718C197.476 116.428 195.422 118.424 194.879 118.831C194.67 118.979 194.502 119.179 194.393 119.41C194.283 119.642 194.235 119.898 194.253 120.153C194.33 120.378 194.469 120.576 194.654 120.724C194.894 120.924 195.344 121.151 196.126 121.077C197.417 120.957 199.649 120.178 202.477 117.74C202.637 117.583 202.728 117.369 202.732 117.144C202.694 116.266 202.587 114.019 202.456 113.234C202.325 112.45 199.699 113.684 198.777 114.154C198.684 114.209 198.606 114.286 198.55 114.378C198.495 114.471 198.464 114.576 198.459 114.684Z"
        fill="#0F152B"
      />
      <path
        d="M202.581 114.489C202.483 114.746 202.038 115.06 201.441 115.251C200.539 115.537 199.279 115.548 198.315 114.68L193.561 82.4646L198.628 81.2648L200.954 80.7163C200.954 80.7163 202.211 83.891 201.591 89.0424C201.264 91.7048 203.062 113.24 202.581 114.489Z"
        fill="#0064AA"
      />
      <path
        d="M202.581 114.489C202.483 114.746 202.038 115.061 201.441 115.251C199.516 111.111 199.729 100.991 199.539 98.1114C199.305 94.4587 197.001 82.4837 197.001 82.4837L198.624 81.2706L200.95 80.7222C200.95 80.7222 202.207 83.8968 201.587 89.0483C201.264 91.7049 203.062 113.24 202.581 114.489Z"
        fill="#014473"
      />
      <path
        d="M194.97 62.9293C194.974 63.1618 194.907 63.3901 194.78 63.5844C194.263 64.4109 192.781 65.2032 191.003 65.7631C191.003 65.7631 190.992 65.7631 190.986 65.7631C188.435 66.561 185.282 66.8714 183.422 66.1268C183.137 66.0062 182.846 65.8691 182.55 65.7154C182.528 65.7001 182.506 65.6861 182.483 65.6735C182.483 65.6735 182.483 65.6735 182.472 65.6735C182.133 65.4831 181.781 65.2755 181.426 65.0375C180.73 64.5728 178.505 62.8741 177.828 62.3085L179.171 59.8975C181.015 61.1829 184.778 62.4284 184.814 62.4817C184.909 62.6074 188.376 62.1618 189.522 61.8685C190.844 61.5353 194.013 60.2955 194.903 62.6303C194.944 62.725 194.966 62.8264 194.97 62.9293Z"
        fill="#499BE9"
      />
      <path
        d="M202.199 65.6983V65.7516C202.202 65.7954 202.202 65.8393 202.199 65.883C202.199 66.4544 202.129 67.1514 202.039 67.9608C201.788 70.1946 201.313 73.1541 201.106 75.8926C201.093 76.0659 201.083 76.2335 201.07 76.4049C201.041 76.9648 201.028 77.5076 201.016 78.0313C200.98 80.3356 201.053 82.2058 200.273 82.5809C199.394 83.0159 198.457 83.3231 197.491 83.4931C197.301 83.5274 197.111 83.5579 196.921 83.575C195.735 83.6978 194.537 83.5366 193.426 83.1046C193.13 82.9914 192.841 82.8584 192.563 82.7066C191.945 82.3669 191.375 81.9461 190.868 81.4554C190.583 81.1967 190.324 80.9096 190.096 80.5984C190.096 80.5984 189.988 79.8119 189.868 78.5626C189.655 76.3687 189.393 72.7484 189.543 69.3243C189.631 67.4009 189.845 65.5364 190.273 64.03C190.844 62.0228 191.777 60.6478 193.295 60.5773C195.875 60.4574 200.49 62.4818 202.005 64.5766C202.142 64.769 202.193 65.1613 202.199 65.6983Z"
        fill="#58A9FF"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M202.199 65.6982C202.183 66.4548 202.128 67.2099 202.034 67.9607C201.783 70.1946 201.307 73.154 201.1 75.8926C201.087 76.0659 201.077 76.2335 201.064 76.4049C201.035 76.9647 201.022 77.5075 201.011 78.0312C200.975 80.3356 201.047 82.2057 200.267 82.5809C199.388 83.0158 198.451 83.323 197.485 83.4931C196.04 79.3167 196.974 69.7527 197.086 67.9378C197.187 66.34 200.362 65.8468 202.199 65.6982Z"
          fill="#D3EAE9"
        />
      </g>
      <path
        d="M194.126 60.0062C194.126 60.0062 194.55 62.5524 195.023 62.8476C195.635 63.2285 197.254 62.8704 197.719 61.7049L197.368 58.4541L194.126 60.0062Z"
        fill="#E5A488"
      />
      <path
        d="M196.263 50.2803C194.742 50.2803 192.515 52.3351 192.182 55.2565C191.764 58.932 192.732 61.1754 195.189 60.2937C196.953 59.6614 198.832 58.8673 199.472 54.1234C199.79 51.7733 197.539 50.2841 196.263 50.2803Z"
        fill="#F3B698"
      />
      <path
        d="M196.569 48.8618C195.036 48.7742 193.354 50.0045 192.496 51.2366C192.293 51.4652 192.093 51.926 190.908 51.2671C190.908 51.2671 190.684 54.6246 194.101 55.3863C195.476 55.693 196.147 55.4854 196.463 55.1312C196.569 55.0991 196.674 55.0603 196.776 55.015C197.252 56.0948 196.438 57.6088 196.438 57.6088L196.769 57.9059C197.105 57.8792 197.586 57.0927 197.586 57.0927L198.421 57.664C200.22 56.0262 200.788 52.943 200.788 52.943C201.518 51.2671 199.518 49.0313 196.569 48.8618Z"
        fill="#0064AA"
      />
      <path
        d="M197.579 57.0924C197.579 57.0924 198.083 54.9119 199.115 55.3784C200.147 55.845 198.545 58.4255 197.594 57.6352L197.579 57.0924Z"
        fill="#F3B698"
      />
      <path
        d="M182.957 60.3187C182.957 60.3187 182.987 59.3265 182.553 58.7951C182.12 58.2638 181.614 56.7955 181.141 57.203C180.667 57.6106 181.401 58.6466 181.141 58.5361C180.88 58.4257 178.193 56.1499 177.897 56.4413C177.6 56.7326 177.908 57.1497 177.908 57.1497C177.908 57.1497 177.106 56.6641 176.86 56.925C176.615 57.1859 176.767 57.5268 177.089 57.8105C177.089 57.8105 176.328 57.9115 177.163 58.7437C177.163 58.7437 176.836 58.8523 177.374 59.4731C177.912 60.0939 179.656 61.8212 181.797 61.8803C181.797 61.8917 183.014 60.7643 182.957 60.3187Z"
        fill="#F3B698"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M202.199 65.7517C202.202 65.7955 202.202 65.8394 202.199 65.8831C202.172 66.1787 202.068 66.4619 201.897 66.7039C200.665 68.5493 195.957 69.9795 192.734 69.9795C191.722 69.9795 190.965 69.2501 190.277 68.993C190.068 68.9169 189.813 68.4731 189.598 68.3722C189.688 66.4468 189.91 66.106 190.338 64.5996C191.026 65.0871 191.359 65.2395 191.627 65.4432C192.34 65.4185 195.225 64.6624 196.206 64.3882C196.348 64.3463 196.521 64.293 196.706 64.2339C197.466 63.994 198.526 63.6626 199.518 63.6626C200.804 63.6626 201.671 64.1996 202.104 65.2585C202.164 65.416 202.197 65.583 202.199 65.7517Z"
          fill="#D3EAE9"
        />
      </g>
      <path
        d="M196.771 64.7882C198.081 64.4225 201.222 63.1085 202.171 65.4243C203.119 67.74 194.451 70.5776 190.762 69.2007C187.073 67.8238 182.747 62.9942 182.04 62.4857C181.574 62.1487 181.8 60.8003 183.031 60.2804C184.261 59.7605 191.967 65.3995 192.068 65.5195C192.169 65.6395 195.628 65.1043 196.771 64.7882Z"
        fill="#58A9FF"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M191.169 176.523H146.025V178.325H191.169V176.523Z" fill="url(#paint16_linear)" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M207.727 166.937H162.582V168.739H207.727V166.937Z" fill="url(#paint17_linear)" />
      </g>
      <path
        d="M146.023 178.472C145.707 178.472 145.401 178.365 145.154 178.169C144.907 177.973 144.732 177.699 144.659 177.392L127.396 104.383C127.354 104.203 127.347 104.017 127.376 103.835C127.406 103.653 127.47 103.479 127.567 103.322C127.664 103.165 127.79 103.028 127.94 102.921C128.089 102.813 128.258 102.735 128.437 102.693C128.616 102.65 128.802 102.643 128.984 102.673C129.166 102.702 129.34 102.767 129.497 102.864C129.653 102.961 129.789 103.088 129.897 103.237C130.005 103.387 130.082 103.556 130.125 103.735L147.38 176.745C147.423 176.924 147.43 177.11 147.401 177.292C147.372 177.474 147.307 177.649 147.21 177.805C147.114 177.962 146.987 178.099 146.838 178.206C146.688 178.314 146.519 178.392 146.34 178.434C146.236 178.458 146.13 178.471 146.023 178.472Z"
        fill="#0064AA"
      />
      <path
        d="M162.581 168.894C162.265 168.894 161.959 168.787 161.711 168.591C161.464 168.394 161.29 168.12 161.217 167.813L143.958 94.8128C143.915 94.6333 143.909 94.4473 143.938 94.2653C143.968 94.0833 144.033 93.9089 144.129 93.752C144.226 93.5952 144.353 93.4589 144.502 93.3511C144.652 93.2433 144.821 93.1659 145 93.1235C145.179 93.0812 145.365 93.0745 145.546 93.104C145.728 93.1335 145.902 93.1986 146.059 93.2955C146.215 93.3924 146.351 93.5193 146.459 93.6688C146.567 93.8184 146.644 93.9877 146.686 94.1672L163.95 167.175C163.992 167.354 163.999 167.541 163.97 167.723C163.941 167.905 163.876 168.08 163.779 168.237C163.682 168.394 163.555 168.53 163.406 168.638C163.256 168.746 163.087 168.823 162.908 168.866C162.8 168.888 162.69 168.897 162.581 168.894Z"
        fill="#0064AA"
      />
      <path
        d="M143.387 166.947C143.156 166.946 142.932 166.869 142.749 166.728C142.566 166.588 142.434 166.39 142.374 166.167C142.314 165.943 142.329 165.706 142.417 165.492C142.505 165.278 142.661 165.099 142.861 164.983L159.418 155.408C159.66 155.268 159.947 155.23 160.216 155.303C160.486 155.375 160.716 155.551 160.855 155.793C160.994 156.035 161.032 156.323 160.96 156.593C160.888 156.862 160.712 157.092 160.47 157.232L143.908 166.8C143.751 166.894 143.571 166.945 143.387 166.947Z"
        fill="#0064AA"
      />
      <path
        d="M139.645 151.151C139.414 151.151 139.188 151.075 139.005 150.934C138.821 150.793 138.689 150.595 138.628 150.371C138.568 150.147 138.584 149.909 138.673 149.695C138.761 149.481 138.918 149.302 139.119 149.186L155.678 139.612C155.919 139.484 156.2 139.455 156.461 139.53C156.723 139.605 156.946 139.779 157.082 140.015C157.218 140.252 157.258 140.532 157.192 140.796C157.126 141.061 156.961 141.29 156.73 141.435L140.17 151.01C140.011 151.102 139.83 151.151 139.645 151.151Z"
        fill="#0064AA"
      />
      <path
        d="M135.972 135.352C135.74 135.352 135.515 135.276 135.331 135.135C135.147 134.994 135.015 134.796 134.955 134.572C134.895 134.348 134.91 134.11 134.999 133.896C135.088 133.682 135.244 133.503 135.445 133.387L152.003 123.811C152.244 123.672 152.531 123.634 152.801 123.706C153.07 123.779 153.3 123.955 153.439 124.197C153.579 124.439 153.617 124.727 153.544 124.996C153.472 125.266 153.296 125.496 153.054 125.636L136.496 135.211C136.337 135.304 136.156 135.352 135.972 135.352Z"
        fill="#0064AA"
      />
      <path
        d="M132.203 119.7C131.971 119.7 131.746 119.624 131.562 119.483C131.378 119.342 131.246 119.144 131.186 118.92C131.126 118.696 131.142 118.458 131.23 118.244C131.319 118.029 131.476 117.85 131.676 117.735L148.234 108.161C148.475 108.033 148.755 108.004 149.017 108.079C149.279 108.154 149.501 108.328 149.638 108.564C149.774 108.8 149.813 109.08 149.748 109.345C149.682 109.61 149.516 109.839 149.286 109.984L132.728 119.559C132.568 119.651 132.387 119.7 132.203 119.7Z"
        fill="#0064AA"
      />
      <path
        d="M146.637 56.0242C146.637 56.0242 147.51 55.5519 147.751 54.9082C147.993 54.2645 149.004 53.0914 148.415 52.8857C147.825 52.68 147.3 53.8379 147.257 53.5541C147.213 53.2704 147.839 49.8025 147.43 49.6939C147.021 49.5854 146.823 50.0615 146.823 50.0615C146.823 50.0615 146.838 49.1226 146.492 49.0407C146.146 48.9588 145.922 49.2635 145.842 49.6844C145.842 49.6844 145.374 49.0826 145.072 50.2157C145.072 50.2157 144.817 49.9872 144.549 50.7661C144.281 51.545 143.674 53.9312 144.694 55.8052C144.694 55.8052 146.281 56.2965 146.637 56.0242Z"
        fill="#F3B698"
      />
      <path
        d="M149.723 70.2328C150.695 71.185 153.577 73.3827 151.887 75.228C148.869 78.5208 143.558 71.1336 142.897 67.2543C142.235 63.375 144.228 57.1952 144.311 56.3268C144.368 55.7554 145.642 55.2736 146.713 56.0773C147.783 56.8809 146.791 66.3954 146.732 66.5363C146.673 66.6772 148.871 69.4024 149.723 70.2328Z"
        fill="#D2E9E6"
      />
      <path
        d="M141.841 127.859C141.841 127.859 139.286 126.636 138.419 126.543C137.552 126.449 135.631 126.324 135.692 127.784C135.753 129.245 137.133 130.405 139.495 130.831C141.857 131.258 143.771 131.264 143.895 130.831C144.019 130.399 143.92 128.411 143.92 128.411L141.841 127.859Z"
        fill="#0F152B"
      />
      <path
        d="M149.97 125.516C149.97 125.516 147.498 124.299 146.627 124.208C145.757 124.116 143.868 124.017 143.931 125.48C143.994 126.942 145.262 128.062 147.614 128.496C149.966 128.931 151.891 128.931 152.014 128.496C152.138 128.062 152.039 126.076 152.039 126.076L149.97 125.516Z"
        fill="#0F152B"
      />
      <path
        d="M140.183 98.1536L140.531 128.211C140.531 128.512 141.068 128.765 141.777 128.828C141.897 128.841 142.019 128.846 142.14 128.843C143.024 128.843 144.22 128.742 144.22 128.394L146.637 107.192L148.358 126.061C148.358 126.377 148.928 126.632 149.689 126.68C149.783 126.692 149.877 126.697 149.972 126.695C150.856 126.695 152.075 126.59 152.075 126.244L152.645 102.309C152.773 101.539 152.942 100.776 153.151 100.024C153.735 98.3973 153.514 95.236 153.514 95.236C153.309 94.6837 149.095 95.3788 146.034 96.0435C145.04 96.2644 144.156 96.4529 143.587 96.6148L143.207 96.7233C142.132 97.0424 141.112 97.5247 140.183 98.1536Z"
        fill="#0064AA"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M146.637 107.192L148.358 126.061C148.358 126.377 148.928 126.632 149.689 126.679L148.265 106.43L149.004 101.168L146.637 107.192Z"
          fill="#BCBEC0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M140.183 98.1533L140.531 128.211C140.531 128.512 141.068 128.765 141.777 128.828V101.633L143.576 96.6069L143.195 96.7155C142.124 97.038 141.108 97.5229 140.183 98.1533Z"
          fill="#BCBEC0"
        />
      </g>
      <path
        d="M153.956 77.2452L153.541 89.7915L153.528 94.9619V97.5196V98.4204C152.876 99.0717 151.86 99.6868 150.419 100.159C148.517 100.786 146.736 101.418 145.013 101.515C144.773 101.529 144.532 101.529 144.292 101.515H144.23C143.766 101.498 143.306 101.429 142.857 101.311C141.831 101.028 140.884 100.514 140.086 99.8087C140.062 99.7839 140.035 99.7649 140.01 99.7382C139.756 95.1181 141.165 84.7676 141.241 84.1734V84.1487C141.241 84.1487 141.252 78.0546 142.102 75.7121C142.148 75.5767 142.204 75.4444 142.267 75.316C142.379 75.0985 142.511 74.8925 142.663 74.7009C143.164 74.0305 143.834 73.5048 144.604 73.1774C146.576 72.3394 148.787 71.5796 149.236 71.5529C149.251 71.5501 149.267 71.5501 149.282 71.5529C149.788 71.6253 153.687 71.77 153.956 77.2452Z"
        fill="#D2E9E6"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M145.521 77.8962C145.068 80.2044 143.809 91.1681 144.123 92.844C144.372 94.198 144.842 99.3913 144.287 101.52H144.224C143.76 101.503 143.3 101.435 142.851 101.317C141.826 101.033 140.878 100.519 140.081 99.8141C140.056 99.7893 140.029 99.7703 140.005 99.7436C139.75 95.1235 141.159 84.7731 141.235 84.1789V84.1541C141.235 84.1541 141.246 78.06 142.096 75.7176C144.123 74.3235 145.802 76.4774 145.521 77.8962Z"
          fill="#D1D3D4"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M144.602 73.175C145.663 73.7158 148.299 73.4549 148.957 73.034C149.615 72.6132 149.284 71.5486 149.284 71.5486C148.974 71.5048 146.665 72.2989 144.602 73.175Z"
          fill="#D1D3D4"
        />
      </g>
      <path
        d="M144.735 62.2477L145.116 62.4743C145.116 62.3734 145.127 62.2972 145.127 62.2972L148.135 62.8323L148.685 69.8787V72.4991C147.962 73.4114 144.859 73.1124 144.766 73.1219C144.673 73.1314 144.368 70.1053 144.363 69.8692C144.363 69.8692 144.363 69.8692 144.363 69.8577C142.783 69.3911 142.096 68.5646 142.912 65.8946C144.038 62.1677 144.735 62.2477 144.735 62.2477Z"
        fill="#F3B698"
      />
      <path
        d="M144.123 67.2887C144.123 67.2887 144.445 67.7933 144.682 67.6086C144.92 67.4239 145.443 66.7326 145.903 67.1554C146.363 67.5781 146.555 70.5833 146.966 70.9242C147.377 71.2651 147.609 71.7812 148.677 71.2422C149.858 70.6557 151.689 69.2559 152.573 66.2717C153.495 63.1523 151.98 61.1431 150.33 60.6366C145.232 59.073 144.648 60.5813 144.376 61.6802C144.053 62.9999 145.563 63.2589 145.563 63.2589C145.563 63.2589 144.517 64.1521 144.568 64.6568C144.619 65.1614 145.011 65.9879 144.123 67.2887Z"
        fill="#0064AA"
      />
      <path
        d="M130.267 64.5331C130.267 64.5331 130.735 63.6589 130.585 62.9886C130.435 62.3182 130.634 60.7814 130.028 60.9337C129.421 61.0861 129.619 62.3411 129.427 62.1278C129.235 61.9145 127.852 58.6751 127.453 58.8103C127.054 58.9455 127.149 59.4502 127.149 59.4502C127.149 59.4502 126.645 58.658 126.31 58.7798C125.976 58.9017 125.959 59.2788 126.12 59.6768C126.12 59.6768 125.4 59.4312 125.77 60.5452C125.77 60.5452 125.432 60.4957 125.635 61.2918C125.839 62.0878 126.643 64.4169 128.524 65.4205C128.516 65.4167 130.111 64.9539 130.267 64.5331Z"
        fill="#F3B698"
      />
      <path
        d="M140.638 74.6966C141.969 74.9537 145.582 75.2051 145.188 77.677C144.477 82.0896 135.985 78.8444 133.302 75.9631C130.619 73.0817 128.891 66.8219 128.484 66.0601C128.216 65.5516 129.018 64.4452 130.351 64.5366C131.684 64.628 136.082 73.1064 136.11 73.2626C136.139 73.4188 139.472 74.4738 140.638 74.6966Z"
        fill="#D2E9E6"
      />
      <path
        d="M121.076 166.765C121.076 166.765 120.072 164.699 119.712 164.914C119.353 165.129 119.556 165.628 119.556 165.628C119.556 165.628 117.121 164.122 116.632 164.036C116.143 163.951 115.126 164.337 115.153 164.895C115.179 165.453 117.1 167.371 118.265 167.992C119.431 168.613 120.287 168.531 120.287 168.531C120.287 168.531 121.37 167.335 121.076 166.765Z"
        fill="#F3B698"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M155.412 229.916C155.412 229.916 186.14 230.628 188.583 227.908C191.026 225.189 155.186 222.919 139.906 227.428L139.624 229.927L155.412 229.916Z"
          fill="url(#paint18_linear)"
        />
      </g>
      <path
        d="M155.671 222.38L156.3 224.103L155.711 228.169H154.933L154.553 226.09L153.585 228.028C153.585 228.028 150.987 228.525 150.398 227.39C149.809 226.255 150.955 225.924 151.645 225.924L152.547 222.328C152.547 222.328 154.572 221.919 155.671 222.38Z"
        fill="#10122B"
      />
      <path
        d="M150.271 211.234C151.246 214.077 152.528 222.628 152.528 222.628C152.784 222.682 153.043 222.72 153.303 222.742C154.851 222.887 155.671 222.38 155.671 222.38C155.924 215.787 153.705 206.981 153.705 206.981C154.351 203.507 154.113 186.655 154.113 186.655C152.142 186.036 150.774 186.846 149.829 187.347C147.493 188.525 147.673 192.393 147.525 195.688C147.314 200.318 149.301 208.388 150.271 211.234Z"
        fill="#0064AA"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M150.271 211.234C151.246 214.077 152.528 222.628 152.528 222.628C152.784 222.682 153.043 222.72 153.303 222.742C153.028 218.815 152.417 211.441 151.592 209.179C150.463 206.042 149.989 196.903 151.402 192.331C152.125 189.99 151.05 188.367 149.826 187.347C147.489 188.526 147.669 192.393 147.521 195.688C147.314 200.318 149.301 208.389 150.271 211.234Z"
          fill="#014473"
        />
      </g>
      <path
        d="M145.441 224.26C145.441 224.26 145.73 224.21 145.863 226.09C145.928 226.989 145.939 226.874 145.58 229.447C145.58 229.447 145.154 229.638 144.967 229.447L144.397 227.295C144.397 227.295 142.745 229.661 141.634 229.826C140.524 229.992 136.464 230.038 136.439 229.306C136.415 228.575 139.014 227.743 139.816 227.093C140.619 226.444 142.341 224.031 142.341 224.031C142.341 224.031 144.948 223.871 145.441 224.26Z"
        fill="#10122B"
      />
      <path
        d="M141.172 198.497C141.868 205.844 142.471 209.173 142.471 209.173L142.345 224.027V224.041C142.345 224.041 144.057 224.568 145.445 224.267C145.445 224.267 147.436 215.871 146.804 209.539C146.804 209.539 149.565 195.176 149.531 194.197C149.531 194.197 152.625 190.959 152.528 188.657C152.469 187.312 148.875 187.539 145.901 187.964C143.789 188.261 141.994 188.657 141.994 188.657C141.994 188.657 140.474 191.153 141.172 198.497Z"
        fill="#0064AA"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M141.172 198.497C141.868 205.844 142.471 209.173 142.471 209.173L142.345 224.027C145.087 218.988 143.878 201.936 143.711 196.16C143.596 193.271 144.36 190.416 145.901 187.971C143.789 188.268 141.994 188.664 141.994 188.664C141.994 188.664 140.474 191.153 141.172 198.497Z"
          fill="#014473"
        />
      </g>
      <path
        d="M155.104 172.108C154.988 176.663 154.024 178.893 153.104 179.07C153.059 179.083 153.013 179.088 152.967 179.085C152.616 179.062 152.27 178.994 151.936 178.882C151.815 178.847 151.693 178.807 151.571 178.771C151.343 178.691 151.117 178.609 150.906 178.527C150.368 178.32 149.955 178.131 149.955 178.131L152.775 165.372L152.874 164.934C153.118 165.202 153.335 165.493 153.522 165.804C153.913 166.418 154.232 167.075 154.473 167.762C154.943 169.16 155.157 170.633 155.104 172.108Z"
        fill="#58A9FF"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M153.104 179.07C153.059 179.082 153.013 179.087 152.967 179.085C152.616 179.062 152.27 178.993 151.936 178.881C151.754 178.72 151.608 178.521 151.51 178.298L153.52 165.8C153.911 166.414 154.23 167.071 154.471 167.758C155.121 171.475 153.537 176.44 153.104 179.07Z"
          fill="#D3EAE9"
        />
      </g>
      <path
        d="M142.488 154.612L143.058 154.726C143.018 154.595 142.99 154.498 142.99 154.498L147.04 153.793L150.959 162.443L151.107 165.389C150.609 166.876 145.928 167.539 145.814 167.594C145.699 167.649 145.603 164.711 145.487 164.418C145.487 164.418 145.487 164.418 145.487 164.402C143.273 164.538 142.634 164.894 141.836 160.058C140.986 154.898 142.488 154.612 142.488 154.612Z"
        fill="#F3B698"
      />
      <path
        d="M143.058 177.883C143.058 177.883 142.825 186.105 141.995 188.647C143.166 189.681 144.679 190.243 146.24 190.224C146.901 190.203 147.736 190.178 148.614 190.096C151.117 189.851 153.963 189.131 154.112 186.668C154.112 186.668 152.798 180.877 152.798 178.038C152.798 175.198 155.532 169.135 153.104 165.031C151.963 163.126 146.742 165.385 146.742 165.385C146.742 165.385 143.095 166.352 142.079 167.607C141.849 167.891 141.634 168.422 141.454 169.093C140.847 171.363 140.64 175.24 141.552 176.663C142.418 177.994 143.058 177.883 143.058 177.883Z"
        fill="#58A9FF"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M143.058 177.883C143.058 177.883 142.825 186.105 141.995 188.647C143.166 189.681 144.679 190.243 146.239 190.224C146.901 190.203 147.736 190.178 148.614 190.096C148.471 189.999 148.297 189.958 148.126 189.98C146.207 190.062 144.365 184.753 144.775 181.306C145.129 178.335 145.39 174.086 145.393 172.037C145.397 169.988 143.435 168.935 143.435 168.935L141.457 169.087C140.851 171.357 140.644 175.234 141.556 176.657C142.418 177.994 143.058 177.883 143.058 177.883Z"
          fill="#D3EAE9"
        />
      </g>
      <path
        d="M144.222 172.218C144.222 172.218 146.07 168.9 143.081 168.251C141.282 167.87 131.461 168.796 131.077 168.613C129.843 168.013 121.068 166.766 121.068 166.766C121.068 166.766 119.368 166.924 120.051 168.649C120.051 168.649 125.474 171.578 130.438 171.77C131.558 171.814 143.108 174.737 144.222 172.218Z"
        fill="#58A9FF"
      />
      <path
        d="M142.98 160.769C140.663 158.483 140.594 155.181 143.361 153.599C146.127 152.016 148.166 153.263 148.166 153.263C150.854 153.364 152.138 157.166 152.018 160.214C151.893 163.439 151.746 164.724 152.172 167.114C152.227 167.367 152.284 167.628 152.343 167.893C152.389 168.072 152.442 168.253 152.499 168.442C152.828 169.483 153.26 171.498 153.26 171.513C154.674 178.028 148.654 177.097 146.747 176.705C145.781 176.514 145.397 174.863 145.241 173.115C145.159 172.574 145.127 172.027 145.146 171.481C145.112 170.365 145.125 169.386 145.085 168.927C144.964 167.564 144.009 167.057 144.427 164.804C144.53 164.225 144.512 163.631 144.374 163.06C144.236 162.489 143.981 161.952 143.625 161.485C143.425 161.233 143.21 160.994 142.98 160.769Z"
        fill="#0064AA"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="80.8676"
          y1="109.059"
          x2="80.8714"
          y2="109.059"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCD24A" />
          <stop offset="1" stopColor="#E5BB2A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="135"
          y1="125"
          x2="169.906"
          y2="167.205"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="47149.6"
          y1="73191.3"
          x2="57306"
          y2="73191.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F55551" />
          <stop offset="1" stopColor="#AE2323" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="78.5"
          y1="152"
          x2="112.229"
          y2="189.329"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="54.2968"
          y1="155.842"
          x2="115.048"
          y2="155.842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#99CAFF" />
          <stop offset="1" stopColor="#5EABFC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="21697"
          y1="9320.49"
          x2="29363.8"
          y2="9320.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#014473" />
          <stop offset="0.45" stopColor="#14193C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="106.948"
          y1="32.6268"
          x2="152.088"
          y2="32.6268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D6A9F" />
          <stop offset="0.31" stopColor="#014473" />
          <stop offset="1" stopColor="#023559" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="135.038"
          y1="46.3687"
          x2="197.233"
          y2="46.3687"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B5C94" />
          <stop offset="1" stopColor="#02395F" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="67767.6"
          y1="11065.1"
          x2="86876.3"
          y2="11065.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A5B94" />
          <stop offset="0.31" stopColor="#014473" />
          <stop offset="1" stopColor="#014473" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="119.5"
          y1="5303.6"
          x2="119.5"
          y2="5303.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCD24A" />
          <stop offset="1" stopColor="#E5BB2A" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="73.3854"
          y1="138.279"
          x2="69.7868"
          y2="153.297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D5EA4" />
          <stop offset="0.51" stopColor="#3D85D0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="67.2837"
          y1="106.514"
          x2="101.816"
          y2="106.514"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.51" stopColor="#1E5FA4" />
          <stop offset="1" stopColor="#58A9FF" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="72.8853"
          y1="67.9933"
          x2="37.8142"
          y2="144.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58A9FF" />
          <stop offset="0.51" stopColor="#2977C9" />
          <stop offset="1" stopColor="#58A9FF" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="57.514"
          y1="116.51"
          x2="101.464"
          y2="116.51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F9DF1" />
          <stop offset="1" stopColor="#2366AE" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="17.0692"
          y1="189.424"
          x2="33.022"
          y2="211.632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="186.626"
          y1="117.259"
          x2="209.956"
          y2="136.115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="146.442"
          y1="177.586"
          x2="149.049"
          y2="188.099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="163"
          y1="168"
          x2="165.607"
          y2="178.512"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="147.5"
          y1="230"
          x2="156.67"
          y2="239.861"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CooperationIcon;
