import * as PropTypes from 'prop-types';
import theme from '../../theme/muiTheme';

const CertifiedIcon = (props) => {
  const { color = theme.palette.warning.main } = props;

  return (
    <svg {...props} width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 18.0042L33.4179 21.9187C33.3445 22.0368 33.3024 22.1718 33.2954 22.311L33.5568 27.0019L29.3818 29.1105C29.2583 29.1727 29.158 29.273 29.0958 29.3965L26.9877 33.5728L22.2976 33.311C22.1591 33.3047 22.0218 33.3417 21.9056 33.4174L17.9917 36L14.0781 33.4174C13.9705 33.3494 13.8457 33.3126 13.7184 33.311H13.6858L8.99601 33.5728L6.88792 29.3965C6.82568 29.273 6.72546 29.1727 6.60195 29.1105L2.42662 27.0019L2.68802 22.311C2.69472 22.1721 2.65769 22.0352 2.58205 21.9187L0 18.0042L2.58205 14.0813C2.65769 13.9648 2.69472 13.8275 2.68802 13.689L2.42662 8.99777L6.60195 6.88951C6.72546 6.82726 6.82568 6.72701 6.88792 6.60347L9.00399 2.42718L13.6941 2.68864C13.8327 2.69534 13.9699 2.65831 14.0861 2.58233L18 0L21.9219 2.58233C22.0381 2.65831 22.1753 2.69534 22.3142 2.68864L27.004 2.42718L29.1121 6.60347C29.1743 6.72701 29.2745 6.82726 29.3981 6.88951L33.5731 9.00607L33.3117 13.697C33.305 13.8358 33.3423 13.9731 33.4179 14.0893L36 18.0042ZM5.95627 18.0042C5.95627 24.6482 11.3572 30.0503 18 30.0503C24.6428 30.0503 30.0434 24.6482 30.0434 18.0042C30.0434 11.3598 24.6428 5.95765 18 5.95765C11.3572 5.95765 5.95627 11.3598 5.95627 18.0042Z"
        fill={color}
        fillOpacity="0.6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 18.0001C8 12.4901 12.4827 8 18.0001 8C23.5176 8 28 12.4901 28 18.0001C28 23.5099 23.5099 28 18.0001 28C12.4901 28 8 23.5099 8 18.0001ZM17.0178 21.8301L22.8176 16.0635C23.0519 15.8292 23.0619 15.4392 22.8396 15.1923C22.6173 14.9453 22.2474 14.9348 22.0131 15.1691L16.6304 20.5195L13.9974 17.6669C13.7703 17.4284 13.4035 17.4269 13.1749 17.6636C12.9461 17.9003 12.9412 18.2873 13.1635 18.5306L16.1987 21.8147C16.3075 21.9346 16.4584 22.0015 16.6155 21.9999C16.7658 22.0021 16.9106 21.9409 17.0178 21.8301Z"
        fill={color}
      />
    </svg>
  );
};

CertifiedIcon.propTypes = {
  color: PropTypes.string,
};

export default CertifiedIcon;
