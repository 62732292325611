import React, { createContext, useReducer } from 'react';

type SelectableTable = {
  option: 'unselected' | 'all';
  exclude: Array<string | number>;
  include: Array<string | number>;
};

type UIContextOptions = {
  modal: undefined | string;
  isOpenSubMenu: boolean;
  isOpenSubMenu2Level: boolean;
  selectableTable: SelectableTable;
  bulkUpdateData: any;
  bulkUpdateCounter: number;
  isOpenBulkUpdateModal: boolean;
  setBulkUpdateModal: (payload: any) => void;
  setBulkUpdateCounter: (payload: any) => void;
  setBulkUpdateData: (payload: any) => void;
  setSelection: (payload: any) => void;
  showModalConfirmation: (modal: any) => void;
  onToggle: (url: string) => void;
  onClickMenuItem: (url: string) => void;
  onSubMenuToggle: (url: string) => void;
  clearBulkUpdateState: () => void;
  clearModalConfirmation: () => void;
};

const initialState: UIContextOptions = {
  modal: undefined,
  isOpenSubMenu: false,
  isOpenSubMenu2Level: false,
  selectableTable: {
    option: 'unselected',
    exclude: [],
    include: [],
  },
  bulkUpdateData: undefined,
  bulkUpdateCounter: 0,
  isOpenBulkUpdateModal: false,
  setBulkUpdateModal: (payload) => {},
  setBulkUpdateCounter: (payload) => {},
  setBulkUpdateData: (payload) => {},
  setSelection: (payload) => {},
  showModalConfirmation: (modal) => {},
  onToggle: (url) => {},
  onClickMenuItem: (url) => {},
  onSubMenuToggle: (url) => {},
  clearBulkUpdateState: () => {},
  clearModalConfirmation: () => {},
};

export const UIContext = createContext<UIContextOptions>(initialState);

const SET_MODAL = 'SET_MODAL';
const CLEAR_MODAL = 'CLEAR_MODAL';
const SET_IS_OPEN_SUB_MENU = 'SET_IS_OPEN_SUB_MENU';
const SET_IS_OPEN_SUB_MENU_2_LEVEL = 'SET_IS_OPEN_SUB_MENU_2_LEVEL';
const SET_SELECTED_TABLE = 'SET_SELECTED_TABLE';
const SET_BULK_UPDATE_DATA = 'SET_BULK_UPDATE_DATA';
const SET_BULK_UPDATE_COUNTER = 'SET_BULK_UPDATE_COUNTER';
const SET_BULK_UPDATE_MODAL = 'SET_BULK_UPDATE_MODAL';
const CLEAR_BULK_UPDATE_STATE = 'CLEAR_BULK_UPDATE_STATE';

function reducer(state: UIContextOptions, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SET_MODAL:
      return { ...state, modal: payload.modal };
    case SET_BULK_UPDATE_MODAL:
      return { ...state, isOpenBulkUpdateModal: payload.isOpenBulkUpdateModal };
    case CLEAR_MODAL:
      return { ...state, modal: undefined };
    case SET_IS_OPEN_SUB_MENU:
      return {
        ...state,
        isOpenSubMenu: payload.url === state.isOpenSubMenu ? null : payload.url,
      };
    case SET_IS_OPEN_SUB_MENU_2_LEVEL:
      return {
        ...state,
        isOpenSubMenu2Level: payload.url === state.isOpenSubMenu2Level ? null : payload.url,
      };
    case SET_SELECTED_TABLE:
      return {
        ...state,
        selectableTable: {
          ...state.selectableTable,
          ...payload,
        },
      };
    case SET_BULK_UPDATE_DATA:
      return {
        ...state,
        bulkUpdateData: payload,
      };
    case SET_BULK_UPDATE_COUNTER:
      return {
        ...state,
        bulkUpdateCounter: payload,
      };
    case CLEAR_BULK_UPDATE_STATE:
      return {
        ...state,
        bulkUpdateCounter: 0,
        selectableTable: {
          option: 'unselected',
          exclude: [],
          include: [],
        },
        isOpenBulkUpdateModal: false,
      };
    default:
      return state;
  }
}

type UIProviderProps = { children: React.ReactNode };

const UIProvider = (props: UIProviderProps) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const setBulkUpdateModal = (payload: any) =>
    dispatch({
      type: SET_BULK_UPDATE_MODAL,
      payload,
    });

  const setBulkUpdateData = (payload: any) =>
    dispatch({
      type: SET_BULK_UPDATE_DATA,
      payload,
    });

  const setBulkUpdateCounter = (payload: any) =>
    dispatch({
      type: SET_BULK_UPDATE_COUNTER,
      payload,
    });

  const setSelection = (payload: any) =>
    dispatch({
      type: SET_SELECTED_TABLE,
      payload,
    });
  const showModalConfirmation = (modal: any) =>
    dispatch({
      type: SET_MODAL,
      payload: { modal },
    });

  const clearModalConfirmation = () =>
    dispatch({
      type: CLEAR_MODAL,
    });

  const onToggle = (url: any) => {
    return function (event: any) {
      event.preventDefault();
      dispatch({
        type: SET_IS_OPEN_SUB_MENU,
        payload: { url },
      });
    };
  };

  const onClickMenuItem = (url: any) =>
    dispatch({
      type: SET_IS_OPEN_SUB_MENU,
      payload: { url },
    });

  const onSubMenuToggle = (url: any) => {
    return function (event: any) {
      event.preventDefault();
      dispatch({
        type: SET_IS_OPEN_SUB_MENU_2_LEVEL,
        payload: { url },
      });
    };
  };

  const clearBulkUpdateState = () =>
    dispatch({
      type: CLEAR_BULK_UPDATE_STATE,
    });

  return (
    <UIContext.Provider
      value={{
        ...state,
        clearBulkUpdateState,
        setBulkUpdateModal,
        setBulkUpdateCounter,
        setBulkUpdateData,
        setSelection,
        onToggle,
        onSubMenuToggle,
        onClickMenuItem,
        showModalConfirmation,
        clearModalConfirmation,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export default UIProvider;
