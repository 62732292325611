import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';

import { getAuthenticationHeader, sleep, trackExpiredSession } from '../utils/general';
import { OKTA_SET_STORAGE_DELAY } from '../constants';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (!process.env.REACT_APP_DEBUG) return false;
  trackExpiredSession(networkError);

  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}.`)
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const devicesLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_DEVICE,
});

const newDevicesLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_NEW_DEVICE,
});

const facilityLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_FACILITY,
});

const marketplaceLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_MARKETPLACE,
});

const userLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_USER,
});

const filesLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_FILE,
});

const flowLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_FLOW,
});

const iotLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_IOT,
});

const goCheckItLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_GOCHECKIT,
});

const authLink = setContext(async (GraphQLRequest, { headers }) => {
  let accessHeader = getAuthenticationHeader();

  if (!accessHeader) {
    await sleep(OKTA_SET_STORAGE_DELAY);
    accessHeader = getAuthenticationHeader();
  }

  return {
    headers: {
      ...headers,
      ...(accessHeader ? accessHeader : {}),
    },
  };
});

export const getApolloClient = (clientUrl, fetchOptions = {}) => {
  return new ApolloClient({
    link: clientUrl,
    fetchOptions,
    cache: new InMemoryCache(),
  });
};

const linkAuth = (clientUrl) => ApolloLink.from([errorLink, authLink.concat(clientUrl)]);

const fetchOptions = {
  mode: 'no-cors',
};

export const devicesClient = getApolloClient(linkAuth(devicesLink));
export const newDeviceClient = getApolloClient(linkAuth(newDevicesLink));
export const facilityClient = getApolloClient(facilityLink, fetchOptions);
export const marketplaceClient = getApolloClient(linkAuth(marketplaceLink));
export const userClient = getApolloClient(userLink, fetchOptions);
export const filesClient = getApolloClient(linkAuth(filesLink));
export const flowClient = getApolloClient(linkAuth(flowLink));
export const iotClient = getApolloClient(linkAuth(iotLink));
export const goCheckItClient = getApolloClient(linkAuth(goCheckItLink));
