export const ACTION_LINKS = {
  view: (id) => `/app/marketplace/${id}`,
  add: (id) => `/app/marketplace/add/${id}`,
};

export const RADIUS_RANGE = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 200,
    label: 200,
  },
];

export const MARKETPLACE_LIST_HEADER = [
  {
    id: 'photo',
    isSortable: false,
    label: 'Photo',
    isVisible: true,
  },
  {
    id: 'technicianFullName',
    isSortable: true,
    label: 'Name',
    isVisible: true,
  },
  {
    id: 'technicianBusinessName',
    isSortable: true,
    label: 'Company',
    isVisible: true,
  },
  {
    id: 'technicianAddressLine',
    isSortable: true,
    label: 'Address',
    isVisible: true,
    style: { minWidth: 240 },
  },
  {
    id: 'technicianRateRange',
    isSortable: true,
    label: 'Rate/hour',
    isVisible: true,
    style: { textAlign: 'center' },
  },
  {
    id: 'technicianCompanyType',
    isSortable: true,
    label: 'Company Type',
    isVisible: true,
    style: { textAlign: 'center' },
  },
  {
    id: 'technicianCertificationCount',
    isSortable: true,
    label: 'Certifications',
    isVisible: true,
    style: { textAlign: 'center' },
  },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto', paddingRight: 0 },
  },
];

export const MIN_RADIUS = 10;

export const SUPPORT_TYPE = {
  DEVICE: 'device',
  FACILITY: 'facility',
  ALL: 'deviceAndFacility',
};
