export const DEVICE_USE = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const DEVICE_USE_OPTIONS = [
  { label: 'Active', value: DEVICE_USE.ACTIVE },
  { label: 'Inactive', value: DEVICE_USE.INACTIVE },
];

export const DEVICE_STATUS_OPTIONS = [
  { label: 'Up', value: 1 },
  { label: 'Down', value: 2 },
];

export const TEXT_VALUES = {
  NO_SERVICE_PROVIDERS: 'No service providers',
  CLICK_HERE_TO_TAG_SERVICE_PROVIDER: 'Click here to Tag Service Provider',
  LOADING: 'Loading...',
};

export const ASSET_ID_NAME = 'assetId';
export const CONTROL_ID_NAME = 'controlId';

export const DEVICE_BULK_UPDATE_FIELDS = {
  DEVICE_DESCRIPTION: 'DEVICE_DESCRIPTION',
  DEVICE_DEVICE_LIFE: 'DEVICE_DEVICE_LIFE',
  DEVICE_DEVICE_USE: 'DEVICE_DEVICE_USE',
  DEVICE_RISK_SCORE: 'DEVICE_RISK_SCORE',
  DEVICE_RECYCLE_COST: 'DEVICE_RECYCLE_COST',
  DEVICE_MANUFACTURE_YEAR: 'DEVICE_MANUFACTURE_YEAR',
  DEVICE_STATUS: 'DEVICE_STATUS',
  DEVICE_PURCHASE_YEAR: 'DEVICE_PURCHASE_YEAR',
  DEVICE_PURCHASE_COST: 'DEVICE_PURCHASE_COST',
  DEVICE_MANAGER: 'DEVICE_MANAGER',
  DEVICE_MODEL: 'DEVICE_MODEL',
  DEVICE_SERVICE_PROVIDER: 'DEVICE_SERVICE_PROVIDER',
  DEVICE_GROUP: 'DEVICE_GROUP',
  DEVICE_FACILITY: 'DEVICE_FACILITY',
  DEVICE_MAINTENANCE: 'DEVICE_MAINTENANCE',
};

export const DEVICE_FIELD_TEXT = {
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_DESCRIPTION]: 'Description',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_DEVICE_LIFE]: 'Device Life',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_DEVICE_USE]: 'Device Use',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_RISK_SCORE]: 'Risk Score',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_RECYCLE_COST]: 'Recycle Cost',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_MANUFACTURE_YEAR]: 'Manufacture Year',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_STATUS]: 'Status',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_PURCHASE_YEAR]: 'Purchase Year',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_PURCHASE_COST]: 'Purchase Cost',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_MANAGER]: 'Device Manager',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_MODEL]: 'Make / Category / Model ',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_SERVICE_PROVIDER]: 'Service Provider',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_GROUP]: 'Group',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_FACILITY]: 'Facility / Area',
  [DEVICE_BULK_UPDATE_FIELDS.DEVICE_MAINTENANCE]: 'Device Maintenance',
};

export const DEVICE_FIELD_OPTIONS = Object.keys(DEVICE_BULK_UPDATE_FIELDS)
  .map((k) => ({
    label: DEVICE_FIELD_TEXT[k],
    value: DEVICE_BULK_UPDATE_FIELDS[k],
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export const DEVICE_MAINTENANCE_STRATEGY = {
  OEM: 1,
  AEM: 2,
  WARRANTY_END_DATE: 3,
  RENTAL: 4,
  NOT_REQUIRED: 5,
};

export const DEVICE_STRATEGY_OPTIONS = [
  { label: 'AEM', value: DEVICE_MAINTENANCE_STRATEGY.AEM },
  { label: 'Not Required', value: DEVICE_MAINTENANCE_STRATEGY.NOT_REQUIRED },
  { label: 'OEM', value: DEVICE_MAINTENANCE_STRATEGY.OEM },
  { label: 'Rental', value: DEVICE_MAINTENANCE_STRATEGY.RENTAL },
  { label: 'Warranty End Date', value: DEVICE_MAINTENANCE_STRATEGY.WARRANTY_END_DATE },
];

export const DEFAULT_STRATEGY = DEVICE_MAINTENANCE_STRATEGY.OEM;
