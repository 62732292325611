import { useEffect, useRef, useCallback } from 'react';

import { useSafeSetState } from '@uptime/shared/hooks';
import { useStore } from '@uptime/shared/hooks/useStore';
import { filesClient } from '@uptime/shared/graphql/apolloClientConfiguration';

export default (variables, query, client) => {
  const entity = Object.keys(variables)[0];

  // TODO: this temporary solution. Remove this part of code in the future
  const storeKey = query.definitions[0].name.value;

  const [store, setStore] = useStore();

  const forceReFetch = useCallback(
    () =>
      setStore({
        [storeKey]: Date.now(),
      }),
    [setStore, storeKey]
  );

  const INIT_VALUES = {
    loaded: false,
    fetching: false,
    url: undefined,
    error: null,
    forceReFetch,
  };

  const [state, safeSetState] = useSafeSetState(INIT_VALUES);

  const doFetch = useCallback(async () => {
    try {
      if (!variables[entity]) {
        safeSetState({ ...INIT_VALUES, fetching: false, forceReFetch });
        return null;
      }
      const result = await (client || filesClient).query({
        query,
        variables,
        fetchPolicy: 'network-only',
      });

      const {
        data: { url },
      } = result;

      safeSetState({ url, fetching: false, loaded: true, forceReFetch });
    } catch (e) {
      safeSetState({ ...INIT_VALUES, fetching: false, forceReFetch });
    }
  }, [INIT_VALUES, entity, forceReFetch, query, safeSetState, variables]);

  // Todo: this temporary solution. Remove this effect in the future
  useEffect(() => {
    safeSetState({ fetching: true, forceReFetch });

    doFetch();
  }, [store[storeKey]]); // eslint-disable-line

  useEffect(() => {
    if (previousEntity.current === variables[entity]) {
      return;
    }

    safeSetState({ fetching: true, forceReFetch });

    doFetch();
  });

  const previousEntity = useRef();

  useEffect(() => {
    previousEntity.current = variables[entity];
  });

  return state;
};
