import { useEffect, useReducer, useRef } from 'react';

export { default as useOnMount } from './useOnMount';
export { default as useOnUpdate } from './useOnUpdate';

export const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  return isMounted;
};

export const useSetState = (initialState) => {
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), initialState);

  return [state, setState];
};

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useSafeSetState = (initialState) => {
  const [state, setState] = useSetState(initialState);

  const mountedRef = useRef();

  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);

  const safeSetState = (...args) => mountedRef.current && setState(...args);

  return [state, safeSetState];
};
