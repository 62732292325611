// Todo: this temporary solution. Remove this hook in the future
import { useState, useEffect } from 'react';

const store = {
  state: {},
  setState(value) {
    this.state = value;
    this.setters.map((setter) => setter(this.state));
  },
  setters: [],
};

store.setState = store.setState.bind(store);

export const useStore = () => {
  const [state, set] = useState(store.state);

  if (!store.setters.includes(set)) {
    store.setters = [...store.setters, set];
  }

  useEffect(
    () => () => {
      store.setters = store.setters.filter((setter) => setter !== set);
    },
    []
  );

  return [state, store.setState];
};
