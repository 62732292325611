import { useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import uuid from 'uuid';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { UIContext } from '@uptime/controller/src/providers/UIProvider';

import useStyles from '../styles';
import Head from './Head';

const DesktopList = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    bodyCells,
    rowStyle = { height: 90 },
    selectable = false,
    customClass,
    tableTitle,
    itemsCount,
  } = props;
  const {
    selectableTable = {},
    bulkUpdateCounter,
    setSelection,
    setBulkUpdateCounter,
    clearBulkUpdateState,
  } = useContext(UIContext);
  const { option, include = [], exclude = [] } = selectableTable;

  const classes = useStyles();

  const cellValue = (val) => {
    if (isNil(val) || val === '') {
      return '-';
    }
    return val;
  };

  const isSelected = (deviceId) => {
    if (option === 'all') {
      return !exclude.includes(deviceId);
    }

    if (option === 'unselected') {
      return include.includes(deviceId);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelection({ option: 'all', include: [], exclude: [] });
      setBulkUpdateCounter(itemsCount);
      return;
    }

    clearBulkUpdateState();
  };

  const handleClick = (event, deviceId) => {
    if (option === 'all') {
      if (event.target.checked) {
        setSelection({
          ...selectableTable,
          exclude: exclude.filter((id) => id !== deviceId),
        });

        setBulkUpdateCounter(bulkUpdateCounter + 1);
      }

      if (!event.target.checked) {
        setSelection({
          ...selectableTable,
          exclude: [...exclude, deviceId],
        });

        setBulkUpdateCounter(bulkUpdateCounter - 1);
      }
    }

    if (option === 'unselected') {
      if (event.target.checked) {
        setSelection({
          ...selectableTable,
          include: [...include, deviceId],
        });

        setBulkUpdateCounter(bulkUpdateCounter + 1);
      }

      if (!event.target.checked) {
        setSelection({
          ...selectableTable,
          include: include.filter((id) => id !== deviceId),
        });

        setBulkUpdateCounter(bulkUpdateCounter - 1);
      }
    }
  };

  return (
    <div className={`${classes.tableWrapper} ${tableTitle && classes.tableTitleWrapper} ${customClass}`}>
      {bulkUpdateCounter > 0 && (
        <Box height={28}>
          <span>Selected: {bulkUpdateCounter}</span>
        </Box>
      )}
      <Table stickyHeader>
        <Head
          order={order}
          orderBy={orderBy}
          headCells={headCells}
          rowCount={itemsCount}
          selectable={selectable}
          onRequestSort={onRequestSort}
          onSelectAllClick={handleSelectAllClick}
          tableTitle={tableTitle}
        />
        <TableBody className={classes.tableBody}>
          {bodyCells.map((row) => {
            const isRowSelected = isSelected(row.deviceId);
            return (
              <TableRow
                hover
                style={rowStyle}
                key={uuid.v4()}
                className={classes.tableRow}
                data-testid="tableRow"
              >
                {selectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      role="checkbox"
                      checked={isRowSelected}
                      onChange={(event) => handleClick(event, row.deviceId)}
                    />
                  </TableCell>
                )}
                {headCells.map((cell) => (
                  <TableCell key={cell.id} style={cell.style}>
                    {cellValue(row[cell.id])}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

DesktopList.propTypes = {
  rowStyle: PropTypes.object,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  bodyCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectable: PropTypes.bool,
  tableTitle: PropTypes.string,
  customClass: PropTypes.string,
  itemsCount: PropTypes.number,
};

export default DesktopList;
