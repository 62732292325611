import PropTypes from 'prop-types';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import NoResultsPlaceholder from '@uptime/shared/components/NoResultsPlaceholder';
import useIsMobileView from '../../hooks/useIsMobileView';

import useStyles from './styles';
import DesktopList from './partials/DesktopList';
import MobileList from './partials/MobileList';

export const ORDER_DESC = 'desc';
export const ORDER_ASC = 'asc';
export const ROWS_PER_PAGE = 10;

/**
 * A component that renders a list with pagination and sorting functionality.
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {boolean} [props.selectable] - Whether the list items are selectable.
 * @param {Function} [props.onSort] - A callback function to handle sorting.
 * @param {Array} props.headCells - An array of objects representing the table header cells.
 * @param {Array} props.data - An array of objects representing the table data.
 * @param {Function} [props.onChangePage] - A callback function to handle pagination.
 * @param {Object} [props.pagination] - An object containing pagination information.
 * @param {string} [props.sort.order] - The current sort order.
 * @param {string} [props.sort.column] - The current sort column.
 * @param {boolean} [props.isOnlyDesktopView] - Whether the list should only be displayed on desktop view.
 * @param {boolean} [props.isAscSortingFirst] - Whether ascending sorting should be the default.
 * @param {boolean} [props.isSynchronousMode=false] - Whether the list should be rendered synchronously.
 * @param {string} [props.tableTitle] - The title of the table.
 * @param {string} [props.customClass] - A custom CSS class to apply to the component.
 * @param {boolean} [props.fetchEveryPageSeparately=false] - Whether to fetch every page separately.
 * @param {boolean} [props.hasBorder=false] - Whether the table has border.
 * @returns {JSX.Element} - The rendered component.
 */
const List = (props) => {
  const defaultOrder = props.isAscSortingFirst ? ORDER_ASC : ORDER_DESC;
  const {
    selectable,
    onSort,
    headCells,
    data,
    onChangePage,
    pagination,
    sort: { order = defaultOrder, column } = {},
    isOnlyDesktopView,
    isAscSortingFirst,
    isSynchronousMode = false,
    tableTitle,
    customClass,
    fetchEveryPageSeparately = false, // fetch every page separately
    hasBorder = false,
    rowStyle,
    isCompact = false,
  } = props;

  const classes = useStyles();
  const borderClass = hasBorder ? classes.tableBorder : '';

  let currentPage = isSynchronousMode ? pagination?.currentPage : pagination?.currentPage - 1;
  if (fetchEveryPageSeparately) {
    currentPage = pagination?.currentPage;
  }
  const itemsCount = pagination?.itemsCount || 0;

  const handleRequestSort = (property) => () => {
    const isSwitchSorting = column === property && order === defaultOrder;
    const descOrder = isSwitchSorting ? ORDER_ASC : ORDER_DESC;
    const ascOrder = isSwitchSorting ? ORDER_DESC : ORDER_ASC;

    onSort({
      column: property,
      order: isAscSortingFirst ? ascOrder : descOrder,
    });
  };

  const tableData = isSynchronousMode
    ? data.slice(currentPage * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE)
    : data;

  const isMobileView = useIsMobileView();

  if (isMobileView === undefined) return null;

  if (!data || data.length === 0) {
    return (
      <Box mt={isCompact ? 0 : 20}>
        <NoResultsPlaceholder />
      </Box>
    );
  }

  return (
    <>
      <div id="first-in-List" className={`${classes.root} ${borderClass} ${customClass}`}>
        {isMobileView && !isOnlyDesktopView ? (
          <MobileList
            headCells={headCells}
            bodyCells={tableData}
            order={order}
            orderBy={column}
            onRequestSort={handleRequestSort}
          />
        ) : (
          <DesktopList
            headCells={headCells}
            bodyCells={tableData}
            order={order}
            orderBy={column}
            onRequestSort={handleRequestSort}
            selectable={selectable}
            rowStyle={rowStyle}
            tableTitle={tableTitle}
            itemsCount={itemsCount}
            customClass={customClass}
          />
        )}
      </div>
      {pagination && tableData.length && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={pagination.itemsCount}
          rowsPerPage={ROWS_PER_PAGE}
          page={currentPage}
          onPageChange={onChangePage}
        />
      )}
    </>
  );
};

List.defaultProps = {
  onSort: () => {},
};

List.propTypes = {
  selectable: PropTypes.bool,
  onSort: PropTypes.func,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangePage: PropTypes.func,
  pagination: PropTypes.shape({
    itemsCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
  }),
  sort: PropTypes.object,
  isOnlyDesktopView: PropTypes.bool,
  isAscSortingFirst: PropTypes.bool,
  isSynchronousMode: PropTypes.bool,
  isNewRequestPerPage: PropTypes.bool,
  tableTitle: PropTypes.string,
  customClass: PropTypes.string,
  hasBorder: PropTypes.bool,
  rowStyle: PropTypes.object,
  isCompact: PropTypes.bool,
  fetchEveryPageSeparately: PropTypes.bool,
};

export default List;
