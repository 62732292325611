import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    flex: 1,
    position: 'relative',
    marginTop: 16,
    '&.marketplace-list': {
      border: `1px solid ${theme.palette.grey[200]}`,
      padding: `0 ${theme.spacing(4)} ${theme.spacing(4)}`,
    },
  },
  tableBorder: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 4,
    '& thead tr th:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    // for lists where 1st column is a button
    '& tbody tr td:first-of-type button': {
      marginLeft: theme.spacing(2),
    },
  },
  tableHeading: {
    '& .MuiTypography-root': {
      fontWeight: 700,
      fontSize: 16,
      color: theme.palette.grey.black,
      marginBottom: theme.spacing(2),
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(0),
      borderBottom: 'none',
      color: theme.palette.grey[800],
    },
  },
  tableTitle: {
    marginTop: theme.spacing(2),
  },
  tableTitleWrapper: {
    '& .MuiTable-root': {
      padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 4,
    },
  },
  tableWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '& .MuiTableSortLabel-active': {
      color: theme.palette.grey.black,
      fontWeight: 600,
    },
    '& .MuiTableCell-head': {
      fontWeight: 500,
      height: 56,
    },
    '&.marketplace-list': {
      '& .MuiTableCell-root': {
        minWidth: 'initial',
        maxWidth: 170,
        wordBreak: 'normal',
      },
    },
  },
  staticTableWrapper: {
    width: '100%',
    overflow: 'auto',
    '& .MuiTableCell-root': {
      minWidth: 110,
      maxWidth: 150,
      wordBreak: 'break-word',
    },
  },
  tableBody: {
    '& .MuiTableCell-root': {
      color: theme.palette.grey.black,
    },
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover .iconWrapper': {
      visibility: 'visible',
    },
  },
  list: {
    alignItems: 'flex-start',
  },
  listItem: {
    padding: theme.spacing(1),
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    maxWidth: '100%',
    '& .MuiListItemText-primary': {
      color: theme.palette.grey.black,
      fontSize: 15,
      order: 1,
    },
    '& .MuiListItemText-secondary': {
      color: theme.palette.grey[500],
      fontSize: 12,
      order: 0,
      [theme.breakpoints.up('md')]: {
        whiteSpace: 'nowrap',
      },
    },
  },
  activeSorting: {
    backgroundColor: theme.palette.grey[100],
    '& .MuiListItemText-secondary': {
      fontWeight: 'bold',
    },
  },
}));
