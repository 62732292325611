export const WIZARD_TITLES = {
  PERSONAL_INFORMATION: 'Personal Information',
  BUSINESS_INFORMATION: 'Business Information',
  DOCUMENTS: 'Supporting Documents',
  DOCUMENT_TYPES: 'Document Types',
  TEAM: 'Add Your Team Member',
  TEAM_LIST: 'Add Your Team Member',
  REPAIR_PAYMENTS: 'Payments',
  FACILITY_PAYMENTS: 'Payments',
  NOTIFICATIONS: 'Notifications',
  SERVICE: 'Tiers of Service',
  COVERAGE: 'Coverage Area',
  EQUIPMENT: 'Equipment Service',
};

export const WIZARD_PAGES = {
  PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
  BUSINESS_INFORMATION: 'BUSINESS_INFORMATION',
  DOCUMENTS: 'DOCUMENTS',
  DOCUMENT_TYPES: 'DOCUMENT_TYPES',
  TEAM: 'TEAM',
  REPAIR_PAYMENTS: 'REPAIR_PAYMENTS',
  FACILITY_PAYMENTS: 'FACILITY_PAYMENTS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SERVICE: 'SERVICE',
  COVERAGE: 'COVERAGE',
  EQUIPMENT: 'EQUIPMENT',
  TEAM_LIST: 'TEAM_LIST',
};

export const ACTION_TYPES = {
  SET_STEP: 'SET_STEP',
  SET_INVOICE_ADDRESS: 'SET_INVOICE_ADDRESS',
  SET_SERVER_STEP: 'SET_SERVER_STEP',
  SET_EDIT_STEP: 'SET_EDIT_STEP',
  SET_STRIPE_AUTH_DATA: 'SET_STRIPE_AUTH_DATA',
  CLEAR_EDIT_STEP: 'CLEAR_EDIT_STEP',
  REQUEST_START: 'REQUEST_START',
  REQUEST_ERROR: 'REQUEST_ERROR',
  REQUEST_END: 'REQUEST_END',
  CLEAR_ERROR: 'CLEAR_ERROR',
  SET_SEARCH: 'SET_SEARCH',
  SAVE_SUB_ACCOUNT: 'SAVE_SUB_ACCOUNT',
  SET_FILTERED_USERS: 'SET_FILTERED_USERS',
  FINALIZE_PERSONAL_STEP: 'FINALIZE_PERSONAL_STEP',
  FINALIZE_BUSINESS_STEP: 'FINALIZE_BUSINESS_STEP',
  FINALIZE_TEAM_STEP: 'FINALIZE_TEAM_STEP',
  FINALIZE_DOCUMENTS_STEP: 'FINALIZE_DOCUMENTS_STEP',
  FINALIZE_DOCUMENT_TYPES_STEP: 'FINALIZE_DOCUMENT_TYPES_STEP',
  FINALIZE_FACILITY_PAYMENT_STEP: 'FINALIZE_FACILITY_PAYMENT_STEP',
  FINALIZE_REPAIR_PAYMENT_STEP: 'FINALIZE_REPAIR_PAYMENT_STEP',
  FINALIZE_SETTINGS_STEP: 'FINALIZE_SETTINGS_STEP',
  FINALIZE_SERVICE_STEP: 'FINALIZE_SERVICE_STEP',
  FINALIZE_COVERAGE_STEP: 'FINALIZE_COVERAGE_STEP',
  FINALIZE_EQUIPMENT_STEP: 'FINALIZE_EQUIPMENT_STEP',
};

export const NOTIFICATION_LABELS = {
  notification: 'Email Notifications',
  personal: 'Settings',
  emailCurrentMonthPm:
    'Send monthly reports of devices requiring preventive maintenance in the upcoming month',
  emailDownDevice: 'Send weekly reports of all devices in "Down" status',
  emailMonthlyDeviceMaintenance: 'Send monthly report of amount spent on device maintenance',
  emailDailyRunTask: 'Send prior day task report',
  emailDailyRunTaskOverdue: 'Send daily overdue task report',
  emailDeviceAssigned: 'Send notifications for maintenance and repair requests',
  showDeleteWarn: 'Show warning before deleting a task',
  isMpSearchable: 'Searchable on marketplace',
  runTaskReportDaily: 'Send daily reports of tasks to be completed',
  initialsRequiredToCompleteTasks: 'Require initials to complete tasks',
  dailyTaskCompletionReportForManager: 'Send daily completion report of tasks to managers',
  dailyTaskOverdueReportForSubAccounts: 'Send daily report of overdue tasks to staff',
  dailyTaskOverdueReportForManager: 'Send daily report of overdue tasks to managers',
  allowSubsControlTaskRemovalWarning: 'Allow staff to remove delete task warning',
  allowToShareWONotes: 'Share job notes with customers',
};

export const EQUIPMENT_HEADERS = [
  { id: 'equipmentCategoryTitle', isSortable: false, label: 'Category', isVisible: true },
  { id: 'equipmentMakeTitle', isSortable: false, label: 'Make', isVisible: true },
  { id: 'certified', isSortable: false, label: 'Certified', isVisible: true, style: { minWidth: 'auto' } },
  { id: 'action', isSortable: false, label: '', isVisible: true, style: { minWidth: 'auto' } },
];

export const TECHNICIAN_SECTIONS = {
  service: 'service',
  coverage: 'coverage',
  equipment: 'equipment',
};

export const DAYS_OPTIONS = [
  {
    label: 'Sun',
    value: 'sun',
  },
  {
    label: 'Mon',
    value: 'mon',
  },
  {
    label: 'Tue',
    value: 'tue',
  },
  {
    label: 'Wed',
    value: 'wed',
  },
  {
    label: 'Thu',
    value: 'thu',
  },
  {
    label: 'Fri',
    value: 'fri',
  },
  {
    label: 'Sat',
    value: 'sat',
  },
];

export const ALL_MAKES_VALUE = 0;

export const NATIONWIDE_LABEL = 'United States';

export const US_CENTER = {
  latitude: 42.116386,
  longitude: -96.299591,
};
