import React from 'react';

import { WIZARD_PAGES } from '@uptime/shared/constants/account';
import {
  PersonIcon,
  DocumentIcon,
  BusinessIcon,
  PaymentIcon,
  NotificationIcon,
  TeamIcon,
} from '@uptime/shared/components/Icons';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import GroupsIcon from '@mui/icons-material/Groups';

export const personal = {
  stepLabel: 'Personal',
  icon: <PersonIcon style={{ fontSize: 24 }} />,
  step: WIZARD_PAGES.PERSONAL_INFORMATION,
};

export const business = {
  stepLabel: 'Business',
  icon: <BusinessIcon style={{ fontSize: 24 }} />,
  step: WIZARD_PAGES.BUSINESS_INFORMATION,
};

export const documents = {
  stepLabel: 'Documents',
  icon: <DocumentIcon style={{ fontSize: 24 }} />,
  step: WIZARD_PAGES.DOCUMENTS,
};

export const documentType = {
  stepLabel: 'Document Types',
  icon: <LibraryBooksIcon style={{ fontSize: 24 }} />,
  step: WIZARD_PAGES.DOCUMENT_TYPES,
};

export const team = {
  stepLabel: 'Your Team',
  icon: <TeamIcon style={{ fontSize: 24 }} />,
  step: WIZARD_PAGES.TEAM,
};

export const teamList = {
  stepLabel: 'Team List',
  icon: <GroupsIcon style={{ fontSize: 24 }} />,
  step: WIZARD_PAGES.TEAM_LIST,
};

export const facilityPayments = {
  stepLabel: 'Payments',
  icon: <PaymentIcon style={{ fontSize: 24 }} />,
  step: WIZARD_PAGES.FACILITY_PAYMENTS,
};

export const repairPayments = {
  stepLabel: 'Payments',
  icon: <PaymentIcon style={{ fontSize: 24 }} />,
  step: WIZARD_PAGES.REPAIR_PAYMENTS,
};

export const notifications = {
  stepLabel: 'Notifications',
  icon: <NotificationIcon style={{ fontSize: 24 }} />,
  step: WIZARD_PAGES.NOTIFICATIONS,
};
